import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';

const HouseForm = ({houses, house, houseHandleChange, nextStep, unitHandler, setAlert}) => { 

    const makeUnitHandler = () => {


      for (let i = 0; i < houses.length; i++) {
        console.log(houses[i].house, house);
        if (houses[i].house === house){      
          unitHandler()
          nextStep()
          return 
        } else {
          setAlert({state: true,message: "Bad house"})
        }
      }
    }

  const handle = (target) => {
    houseHandleChange(target)
  }

 return (
    <div>
    <div className="house--card">
      <h1>House number</h1>
      <TextInput className = "input" onChange = {houseHandleChange} value = {house} onSelect={handle} trigger={[""," "]} options={houses.map((ho) => ho.house)}/>
      <button className='Button' onClick={() =>makeUnitHandler()}>search</button>
    </div>
    </div>
  );
};

export default HouseForm;