import ModalBase from "./ModalBase"

const GenericModal = ({children, state, setState}) => {
 
  const ModalComponent = () => {
    return (
        <div id="modal1" className="main-modal">
          <div className="main-modal-content">
          {children}
          </div>
        </div>
    )
  }
  return (
    <>
        {state ?  <div className="main-modal-component"><ModalBase close={()=>setState(false)} /><ModalComponent></ModalComponent> </div>  : null}
    </>
  );
};

export default GenericModal

