import UserWidget from "./UserWidget";
import ParkingWidget from './parkingWidget/ParkingWidget'
import LocationWidget from './LocationWidget'
import AdminNavBar from "../../UIComponents/NavBars/AdminNavBar"
import { useState } from "react";
import { app } from "../../config";
import { getAuth, signOut } from "@firebase/auth";

const Admin = ({settedPasses, locationNumber, email}) => {

  const [state,setState] = useState(1)

  const Selection = () => {
    switch(state){
      case 1:
        return <LocationWidget locationNumber = {locationNumber}/>
      case 2:
        return  <UserWidget locationNumber = {locationNumber} settedPasses = {settedPasses}/> 
      case 3:
        return <ParkingWidget locationNumber = {locationNumber}/>
      default: 
        return <LocationWidget locationNumber = {locationNumber}/>
  }
}

const Out = () => {
  signOut(getAuth(app));
  window.location.href = "/adminlogin";
};

    return ( <>
    <AdminNavBar email = {email} Out ={Out} setState = {setState} state = {state}/>
      <Selection/>
  </> );
}
 
export default Admin;