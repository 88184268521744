import {
  getSettedPasses,
  getCapacity,
  getRenovationTime,
  updateSettedPasses,
  updateRenovationTime,
  updateParkingCapacity,
  updateDueTimeHours,
  getDueTimeHours,
  updateForgiveChances,
  getForgiveChances,
  updateForgiveChancesRenovationTime,
  getforgiveChancesRenovationTime,
} from "../../Firebase/Firebase";
import { useState, useEffect } from "react";
import GenericModal from "../../UIComponents/GenericModal";
import ReactLoading from 'react-loading';
import { AiFillCheckCircle } from "react-icons/ai/";

const LocationWidget = ({ locationNumber }) => {
  const [state, setState] = useState({
    settedPasses: 0,
    capacity: 0,
    renovationTime: "day",
    dueTime: 0,
    forgiveChances: 0,
    forgiveChancesRenovationTime: "day",
  });

  const [initialState, setInitialState] = useState({
    settedPasses: 0,
    capacity: 0,
    renovationTime: "day",
    dueTime: 0,
  });

  const [modalContent, setModalContent] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  useEffect(async () => {
    const renovationTime = getRenovationTime(locationNumber);
    const settedPasses = getSettedPasses(locationNumber);
    const capacity = getCapacity(locationNumber);
    const dueTime = getDueTimeHours(locationNumber);
    const forgiveChances = getForgiveChances(locationNumber);
    const forgiveChancesRenovationTime = getforgiveChancesRenovationTime(locationNumber)

    const results = await Promise.all([
      renovationTime,
      settedPasses,
      capacity,
      dueTime,
      forgiveChances,
    ]);
    setState({
      settedPasses: results[1],
      renovationTime: results[0],
      capacity: results[2],
      dueTime: results[3],
      forgiveChances:results[4],
      forgiveChancesRenovationTime: results[5],
    });
    setInitialState({settedPasses: results[1],
      renovationTime: results[0],
      capacity: results[2],
      dueTime: results[3],})
  }, []);

  const handleChange = ({ target }) => {
    setState({
      ...state,
      [target.name]:
        target.name === "renovationTime" || target.name === "forgiveChancesRenovationTime" ? target.value : Number(target.value),
    });
  };


  const update = async () => {
    setLoadingModal(true)
    setModalContent(<div className="main-modal-content"> <h2>Loading</h2><ReactLoading type={"bubbles"} color={"#9B27AF"} height={"50%"} width={"50%"} /></div>)
    if (state.dueTime !== initialState.dueTime) {
     await updateDueTimeHours(locationNumber, state.dueTime);
    }
    if(state.settedPasses !== initialState.settedPasses){
      await updateSettedPasses(locationNumber, state.settedPasses);
    }
    if(state.capacity !== initialState.capacity) {
     await updateParkingCapacity(locationNumber, state.capacity);
    }
    if (state.renovationTime !== initialState.renovationTime) {
      await updateRenovationTime(
        locationNumber,
        state.renovationTime
      );
    }
    //new🚧🚧🚧🚧🚧🚧🚧🚧🚧
    if (state.forgiveChances !== initialState.forgiveChances) {
      await updateForgiveChances(
        locationNumber,
        state.forgiveChances
      );
    }

    if (state.forgiveChancesRenovationTime !== initialState.forgiveChancesRenovationTime) {
      await  updateForgiveChancesRenovationTime(
        locationNumber,
        state.forgiveChancesRenovationTime
      );
    }

   


    setLoadingModal(false)
    setSuccessModal(true)
    setModalContent(<>  <AiFillCheckCircle size="5rem" color="#198754" />
    <h1>Success!</h1></>)
  };



  return (
    <div className="location--container">
      <div className="location--widget">
        <h2>Location Configuration Options</h2>

        <div className="options--container">
          <div className="column">

          <div className="row">
              <div className="row--box">
                <h3>Community Definition</h3>
                <label htmlFor="building--input">Description of Units</label>
                <select
                  name="buildingType"
                  onChange={handleChange}
                  id=""
                  value={state.buildingType}
                  className="building--input"
                >
                  <option value="House">1-4 Units Residential Family Homes</option>
                  <option value="Building">Residential Buildings</option>
                  <option value="Building">Commercial Units</option>
                </select>
              </div>
            </div>

          <div className="row">
              <div className="row--box">
                <h3>Maximum Parking Time</h3>
                <label htmlFor="passes--input">Hours</label>
                <input
                  type="number"
                  onChange={handleChange}
                  name="dueTime"
                  className="passes--input"
                  value={state.dueTime}
                />
              </div>
            </div>

            <div className="row">
              <div className="row--box">
                <h3>Validity of Parking Passes Assigned to each Unit</h3>
                <label htmlFor="interval--input">Passes are replenished every</label>
                <select
                  name="renovationTime"
                  onChange={handleChange}
                  id=""
                  value={state.renovationTime}
                  className="interval--input"
                >
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
              </div>
            </div>
          </div>

          <div className="column">

          <div className="row">
              <div className="row--box">
                <h3>Number of Passes per Unit</h3>
                <div className="field--container">
                  <label htmlFor="passes--input">Passes per {state.renovationTime}</label>
                  <input
                    type="number"
                    onChange={handleChange}
                    name="settedPasses"
                    className="passes--input"
                    value={state.settedPasses}
                  />
                </div>
              </div>
            </div>
           

            <div className="row">
              <div className="row--box">
                <h3>Parking Lot Capacity</h3>
                <label htmlFor="passes--capacity">Vehicles</label>
                <input
                  onChange={handleChange}
                  name="capacity"
                  className="passes--input"
                  type="number"
                  value={state.capacity}
                />
              </div>
            </div>
          </div>

          <div className="column">
          <div className="row">
              <div className="row--box">
                <h3>Parking Violations Forgiveness Opportunities</h3>
                <label htmlFor="passes--capacity">Number of pardons per vehicle</label>
                <input
                  onChange={handleChange}
                  name="forgiveChances"
                  className="passes--input"
                  type="number"
                  value={state.forgiveChances}
                />
              </div>
            </div>
            <div className="row">
              <div className="row--box">
                <h3>Parking Violations Reset Interval</h3>
                <label htmlFor="interval--input">Parking violations are forgotten starting every:</label>
                <select
                  name="forgiveChancesRenovationTime"
                  onChange={handleChange}
                  id=""
                  value={state.forgiveChancesRenovationTime}
                  className="interval--input"
                >
                  <option value="day">Days</option>
                  <option value="week">Weeks</option>
                  <option value="month">Months</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="row--box">
                <h3>Explanation</h3>
                <p>Each vehicle will be pardoned for parking violations</p>
                <p>{state.forgiveChances} times every {state.forgiveChancesRenovationTime}</p>
              </div>
            </div>

            </div>

        </div>

        <button onClick={update}>process</button>
      </div>
      <GenericModal state={loadingModal} setState={""}>
        {modalContent}
      </GenericModal>
      <GenericModal state={successModal} setState={setSuccessModal}>
        {modalContent}
      </GenericModal>
    </div>
  );
};

export default LocationWidget;
