import { HiMenu } from "react-icons/hi/";
import { CgClose } from "react-icons/cg/";
import { useState } from "react";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";

const NavLinks = (props) => {
  const params = useParams()
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };
  return (
    <>
      <div className="nav--element nav--element--center">
      <a className="nav--locationNumber" href="/#">Location: {!params.locationNumber? "no code":params.locationNumber }</a>
      </div>
      <ul className="navList nav--element nav--element--right">
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.05 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="/#">about</a>
        </motion.li>
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="/#">terms</a>
        </motion.li>
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="/login">Login</a>
        </motion.li>
      </ul>
    </>
  );
};

const Navigation = () => {
  return (
    <nav className="navBar">
      {/*  <img className ="logo" src="/Fomofood.png" alt="" /> */}
      <div className="nav--element nav--element--left">
      <h2>Logo</h2>
      </div>
      <NavLinks />
    </nav>
  );
};

const NavigationMobile = () => {
  const [toggle, setToggle] = useState(false);

  const hamburguerIcon = (
    <HiMenu
      className="hamburguer"
      size="2.5em"
      color="white"
      onClick={() => setToggle(!toggle)}
    />
  );
  const closeIcon = (
    <CgClose
      className="hamburguer"
      size="2em"
      color="white"
      onClick={() => setToggle(!toggle)}
    />
  );

  const closeMobileMenu = () => setToggle(false);

  return (
    <nav className="navBarMobile">
      {/*  <img className ="mlogo" src="/Fomofood.png" alt="" /> */}
      <h2>Logo</h2>
      {toggle ? closeIcon : hamburguerIcon}
      {toggle && <NavLinks isMobile={true} closeMobileMenu={closeMobileMenu} />}
    </nav>
  );
};

const PublicNavBar = () => {
  return (
    <>
      <Navigation />
      <NavigationMobile />
    </>
  );
};

export default PublicNavBar;
