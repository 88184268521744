import { useState } from "react";
import ModalBase from "./ModalBase";

const Modal = ({ handleChange, Filter, form }) => {
  const [modal, setModal] = useState(false);

  const toggleFilter = () => {
    Filter();
    let btn = document.getElementById("btn");
    btn.addEventListener("click", setModal(false));
    // devolver la pagina
  };

  return (
    <>
      <div className="modal-component">
        {modal ? (
          ""
        ) : (
          <button className="modal--button" onClick={() => setModal(true)}>
            Filter
          </button>
        )}
        {modal ? (
          <>
            <ModalBase close={() => setModal(false)} />{" "}
            <div id="modal1" className="modal">
              <div className="modal-content">
                <h1>Filter</h1>
                <select
                  onChange={handleChange}
                  name="selector"
                  value={form.selector}
                >
                  <option value="street">Street</option>
                  <option value="plate">Plate</option>
                </select>
                <input
                  name="search"
                  onChange={handleChange}
                  value={form.search}
                />
                <button id="btn" onClick={toggleFilter}>
                  search
                </button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Modal;
