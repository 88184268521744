import { useState, useEffect} from "react";
import PublicNavBar from "../UIComponents/NavBars/PublicNavBar";
import { Link, useParams } from "react-router-dom";
import { getOwnerUserByCode } from "../Firebase/Firebase";
import Alert from "../UIComponents/Alert";

const FormSplitter = () => {
  const params = useParams();
  const [code, setCode] = useState({ cod: "", locationNumber: params.locationNumber });
  const [isActive, setisActive] = useState(false);
  const [alert, setAlert] = useState({ state: false, message: " " });

  const handleChange = ({ target }) => {
    setCode((state) => ({
      ...state,
      [target.name]: target.value.replace(/\s/g, ""),
    }));
  };

  useEffect(() => {
    if (code.cod.length === 0 ) {
      setisActive(false);
    } else {
      setisActive(true);
    }
  }, [code]);

  const AlertComponent = () => {
    return alert.state ? (
      <Alert className="alert alert__warning">
        <p>{alert.message}</p>
      </Alert>
    ) : null;
  };

  const codeChecking = async () => {
    const ownerUser = await getOwnerUserByCode(code.locationNumber, code.cod);
    if (ownerUser) {
      if (ownerUser.passesAvail) {
        window.location.href = `generalformwithcode/${code.locationNumber}/${ownerUser.street}/${ownerUser.house}/${ownerUser.unit}`;
      } else {
        setAlert({ state: true, message: "this unit doesn't have passes" });
      }
    } else {
      setAlert({ state: true, message: "Bad location Number or code" });
    }
  };

  return (
    <>
      <div className="form--splitter">
        <PublicNavBar />
        <AlertComponent />
        <div className="splitter--container">
          <div className="splitter--form">
            <h1>Have a parking pass?</h1>
            <div className="button--container">
              <input
                onChange={handleChange}
                name="cod"
                type="text"
                value={code.cod}
                placeholder="type it here"
              />
              <button
                onClick={codeChecking}
                className={isActive ? "Link" : "Link Link__desactivated"}
              >
                Submit
              </button>
            </div>
            <h1>I dont have a parking code</h1>
            <Link className="Link" to= {`id/${!params.locationNumber? "0": params.locationNumber }`}>
              Click Here
            </Link>

            <h1>Report and be rewarded </h1>
            <p>
            View vehicles with expires permits
            </p>
            <div className="button--container">
              <Link className="Link" to="/login">
                Login
              </Link>
              <Link className="Link" to="/signup">
                Signup
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSplitter;
