import {
    collection,
    getDocs,
    query,
    where,
    orderBy,
  } from "@firebase/firestore";
import db from "../../../config"
import { useParams } from "react-router-dom";
import Modal from "../../../UIComponents/Modal";

const ParkingWidgetFilter = ({setForm, setPeople, changePag ,setpaginterval, setCurrentPage,  paginterval, form}) => {
    const params = useParams();
    
    const Filter = async () => {
        const querySnapshot = await getDocs(
          query(
            collection(db, `Locations/${params.locationNumber}/parking`),
            where(form.selector, "==", form.search),
            orderBy("date", "desc")
          )
        );
        const Array = [];
        querySnapshot.forEach((doc) => {
          Array.push({ data: doc.data(), id: doc.id });
        });
        setPeople(Array);
        changePag({number:1, setpaginterval:setpaginterval, setCurrentPage:setCurrentPage, paginterval: paginterval});
      };


    const handleChange = ({ target }) => {
        setForm((state) => ({
          ...state,
          [target.name]: target.value,
        }))
      };

    return ( 
        <>   
    <div className="p3-container-1">
    <h1>Parking History</h1>
    <div className="p3-search">
      <p>Filter</p>

      <select
        onChange={handleChange}
        name="selector"
        value={form.selector}
      >
        <option value="street">Street</option>
        <option value="plate">License Plate</option>
      </select>

      <input name="search" onChange={handleChange} value={form.search} />
      <button onClick={Filter}>Search</button>
    </div>
  </div> 
    <Modal
          handleChange={handleChange}
          Filter={Filter}
          form={form}
          setForm={setForm}
        />
  </>)
}
 
export default ParkingWidgetFilter;