//https://qawithexperts.com/article/javascript/read-excel-file-using-javascript-xlsx-or-xls/239
import { read, utils} from 'xlsx';
import {xlsJson} from '../../Firebase/Firebase'

const JSONtoXLS = async (locationNumber,settedPasses) => {

        //Reference the FileUpload element.
        var fileUpload = document.getElementById("fileUpload");
        //Validate whether File is valid Excel file.
        var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
        if (regex.test(fileUpload.value.toLowerCase())) {
            if (typeof (FileReader) != "undefined") {
                var reader = new FileReader();
                //For Browsers other than IE.
                if (reader.readAsBinaryString) {
                    reader.onload = async function  (e) {
                      await GetTableFromExcel(e.target.result)
                    };
                    reader.readAsBinaryString(fileUpload.files[0]);
                } else {
                    //For IE Browser.
                    reader.onload = async function (e) {
                        var data = "";
                        var bytes = new Uint8Array(e.target.result);
                        for (var i = 0; i < bytes.byteLength; i++) {
                            data += String.fromCharCode(bytes[i]);
                        }
                        return await GetTableFromExcel(data);
                    };
                    reader.readAsArrayBuffer(fileUpload.files[0]);
                }
            } else {
                alert("This browser does not support HTML5.");
            }
        } else {
            alert("Please upload a valid Excel file.");
        }

        const GetTableFromExcel = async (data) => {
            //Read the Excel File data in binary
            const workbook = read(data, {
                type: 'binary'
            });
            //get the name of First Sheet.
            const Sheet = workbook.SheetNames[0];
            //Read all rows from First Sheet into an JSON array.
            const excelRows = utils.sheet_to_row_object_array(workbook.Sheets[Sheet]);
            console.log(excelRows)
            await xlsJson(excelRows,locationNumber,settedPasses)
        }

}


export default JSONtoXLS






