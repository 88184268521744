import { FaArrowRight, FaArrowLeft } from "react-icons/fa/";

const PaginationParkingWidget = ({
  steps,
  setpaginterval,
  setCurrentPage,
  paginterval,
  setStepsShrink,
  stepsShrink,
  currentPage,
}) => {
  //Pagination
  const next = (number) => {
    setpaginterval({
      ...paginterval,
      initial: 7 * number - 7,
      final: 7 * number,
    });
    setCurrentPage(number);
    if (number < 6) {
      setStepsShrink({ initial: 0, final: 5 });
    } else {
      setStepsShrink({ initial: number - 1, final: number + 4 });
    }
  };

  const changePag = (number) => {
    setpaginterval({
      ...paginterval,
      initial: 7 * number - 7,
      final: 7 * number,
    });
    setCurrentPage(number);
  };

  return (
    <div className="pagination">
      <button
        onClick={() => next(currentPage - 1)}
        className="pagination-navigation"
        style={
          currentPage === 1 ? { pointerEvents: "none" } : { cursor: "pointer" }
        }
      >
        <FaArrowLeft
          size="1rem"
          color={currentPage === 1 ? "#bebebe" : "#6A0080"}
        />
      </button>
      {steps.slice(stepsShrink.initial, stepsShrink.final).map((step) => {
        return (
          <button
            key={step.value}
            className="pagination-numbers"
            style={
              currentPage === step.value
                ? { backgroundColor: "#6A0080", color: "#fff" }
                : null
            }
            onClick={() => changePag(step.value)}
          >
            {" "}
            {step.value}
          </button>
        );
      })}

      <button
        onClick={() => next(currentPage + 1)}
        className="pagination-navigation"
        style={
          currentPage >= paginterval.pages
            ? { pointerEvents: "none" }
            : { cursor: "pointer" }
        }
      >
        <FaArrowRight
          size="1rem"
          color={currentPage >= paginterval.pages ? "#bebebe" : "#6A0080"}
        />
      </button>
    </div>
  );
};

export default PaginationParkingWidget;
