import AlertComponent from '../../UIComponents/Alert'
import { Link, useParams } from 'react-router-dom'
import { useState } from 'react'

const LocationForm = ({checkLocationNumber, locationNumber, locationNumberHandleChange, nextStep, streetHandler, setAlert}) => {

   
    const locationNumberHandler = async() =>{
        const locationN = await checkLocationNumber(locationNumber)
        if (locationN) {
          streetHandler()
          nextStep() 
          window.location.replace = `${locationNumber}`
        } 
        else {
          setAlert({state: true,message: "Bad location Number"})
        } 
    }
    return ( <div>
      <AlertComponent state = {alert.toString()} message = 'bad location number' type = 'warning'/>
      <div className="location--card">
        <h1>Location Number</h1>
        <input type="text" name='locationNumber' value={locationNumber} onChange={locationNumberHandleChange} />
        <button className='Button' onClick={locationNumberHandler}>Check</button>
     {/*    <div>
        <button onClick={() =>nextStep()}>next</button>
        </div> */}
      </div> 
    </div> );
}
 
export default LocationForm;