const PersonalInfo = ({plate,vehicleColor, personalInfoHandleChange, policiesCheckHandleChange, policiesCheck, submitButton, nextStep}) => {
   
    return ( 
        <div className="info--container">
        <div className="info--card">

        <h1>Personal info</h1>
        <div className="field">
        <p>License plate <span>* required</span> </p>
        <input
          className="info--input"
          name="plate"
          onChange={personalInfoHandleChange}
          value={plate}
        />
        {plate.length > 14 ? (
          <p className="validations">max digit its 14 characters</p>
        ) : (
          <></>
        )}
        </div>
        <div className="field">
        <p>Select the vehicle color</p>
        <input type="color" id="favcolor" name="vehicleColor" value={vehicleColor} onChange={personalInfoHandleChange}/>
        </div>
      <div className="check--group">
        <input
          type="checkbox"
          checked={policiesCheck.check}
          onChange={policiesCheckHandleChange}
          name='check'
          className="check"
        />
        <p style={{margin:'0'}}> I read the</p> <a className="terms">terms of use</a>
      </div>
        <button onClick = {nextStep} className = {submitButton ? "Button" : "Button Button__desactivated"}>Submit</button>
        </div>
      </div>
     );
}
 
export default PersonalInfo;