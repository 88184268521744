import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";

const UnitForm = ({
  units,
  unit,
  unitHandleChange,
  nextStep,
  setAlert
}) => {

  const checkUnit = () => {
    for (let i = 0; i < units.length; i++) {
      if (units[i].unit === unit){      
        nextStep()
        return 
      } else {
        setAlert({state: true,message: "Bad unit"})
      }
    }
  }

  const handle = (target) => {
    unitHandleChange(target);
  };

  return (
    <div>
      <div className="unit--card">
        <h1>Choose the Unit</h1>
        <TextInput
          className="input"
          onChange={unitHandleChange}
          value={unit}
          onSelect={handle}
          trigger={["", " "]}
          options={units.map((un) => un.unit)}
        />
        <button className="Button" onClick={checkUnit}>
          Next
        </button>
      </div>
    </div>
  );
};

export default UnitForm;
