import { useState, useEffect } from "react";
import AbsoluteNavBar from "../UIComponents/NavBars/AbsoluteNavBar";
import {createUserLite} from '../Firebase/Firebase'
import Alert from "../UIComponents/Alert";

const SignUp = () => {
  const [form, setform] = useState({
    email: "",
    phone: "",
    password: "",
  });
  const [check, setCheck] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [alert, setAlert] = useState({ state: false, message: " " });

  const handleChange = ({ target }) => {
    setform((state) => ({
      ...state,
      [target.name]: target.value,
    }));
  };

  const policiesCheckHandleChange = () => {
    setCheck(!check);
  };

  useEffect(() => {
    if (
      !check ||
      form.email.length === 0 ||
      form.password.length === 0 ||
      form.phone.length === 0
    ) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [form, check]);

  const AlertComponent = () => {
    return alert.state ? (
      <Alert className="alert alert__warning">
        <p>{alert.message}</p>
      </Alert>
    ) : null;
  };

  const submit = async () => {
    const confirmation = await createUserLite({email: form.email, phone: form.phone, password:form.password})
    setform({ email: "", phone: "", password: ""})
  };

  return (
    <div className="Page_2">
      <div className="alertContainer">
        <AlertComponent />
      </div>
      <AbsoluteNavBar className="navBar navBar__light" />
      <div className="filterTwo">
        <div className="Page2--container">
          <div className="Page2--card">
            <div className="card2--content">
              <h1 className="Page2--Tittle">Sign Up</h1>
              
              <div className="form--field">
                <p>Email</p>
                <input
                  type="email"
                  className="username"
                  name="email"
                  onChange={handleChange}
                  value={form.email}
                />
              </div>

              <div className="form--field">
                <p>Phone</p>
                <input
                  type="phone"
                  className="username"
                  name="phone"
                  onChange={handleChange}
                  value={form.phone}
                />
              </div>

              <div className="form--field">
                <p>Password</p>
                <input
                  type="password"
                  className="password"
                  name="password"
                  onChange={handleChange}
                  value={form.password}
                />
              </div>
              <div className="check--group">
                <input
                  type="checkbox"
                  checked={check}
                  onChange={policiesCheckHandleChange}
                  name="policiesCheck"
                  className="check"
                />
                <label> I read the</label> <a className="terms" href="terms">terms of use</a>
              </div>
              <button
                className={
                  isActive
                    ? "login--btn"
                    : "login--btn login--btn__desactivated"
                }
                onClick={submit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
