import db from "../../../config";
import { useState } from "react";
import { app } from "../../../config";
import {
  collection,
  query,
  Timestamp,
  orderBy,
  onSnapshot,
} from "@firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import GenericModal from "../../../UIComponents/GenericModal";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAuth } from "@firebase/auth";
import { CgDanger } from "react-icons/cg/";
import { report, reportWithoutRegister } from "../../../Firebase/Firebase";
import PaginationParkingWidget from "./PaginationParkingWidget";
import ParkingWidgetFilter from "./ParkingWidgetFilter";
import ReactLoading from "react-loading";
import Alert from "../../../UIComponents/Alert";
import ReportModal from "./ReportModal";
import ReportModalNoRegister from "./ReportModalNoRegister";


const ParkingWidget = ({ locationNumber }) => {
  const [people, setPeople] = useState([]);
  const [paginterval, setpaginterval] = useState({
    initial: 0,
    final: 0,
    pages: 0,
  });
  const [steps, setSteps] = useState([{ value: 1 }]);
  const [stepsShrink, setStepsShrink] = useState({ initial: 0, final: 5 });
  const [currentPage, setCurrentPage] = useState(1);
  const [form, setForm] = useState({ search: "", selector: "house" });
  const [modalContent, setModalContent] = useState(null);
  const [currentUser, setCurrentUser] = useState(false);
  const [reloadPeople, setReloadPeople] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [reportModalResult, setReportModalResult] = useState(false);
  const [buttonDesactivated, setButtonDesactivated] = useState({
    report: false,
    dissaprove: false,
  });
  const [alert, setAlert] = useState({
    state: false,
    message: "",
    type: "alert alert__warning",
  });

  // los estados deben ser estados globales que almacenen la placa y el carro a reportar deben ser globales
  const [reportUserModal, setReportUserModal] = useState({
    cond: false,
    plate: "",
    files: null,
  });

  const [openReportUserModalNoRegister, setopenReportUserModalNoRegister] = useState(false)
  const params = useParams();
  let userLog = true;

  // TODO: Replace the following with your app's Firebase project configuration
  // See: https://firebase.google.com/docs/web/learn-more#config-object
  const firebaseConfig = {
    // ...
    storageBucket: "gs://park7-c5305.appspot.com",
  };
  // Initialize Cloud Storage and get a reference to the service
  const storage = getStorage(app);
  const storageRef = ref(storage, "images");

  useEffect(() => {
    getAuth(app).onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(true);
        const q = query(
          collection(db, `Locations/${params.locationNumber}/parking`),
          orderBy("date", "desc")
        );
        onSnapshot(q, (querySnapshot) => {
          const parking = [];
          querySnapshot.forEach((doc) => {
            parking.push({ id: doc.id, data: doc.data() });
          });
          setPeople(parking);
        });
      } else if (!user && userLog) {
        window.location.href = "/login";
      }
    });
  }, [reloadPeople]);
  //cambiar este estado de people, a otro estado que controle la funcion disapprove y report para
  //actualizar el array cada vez que el ususario haga click en un boton
  //pagination
  useEffect(() => {
    if (people.length > 7) {
      const pages = Math.ceil(people.length / 7);
      setpaginterval({ initial: 0, final: 7, pages: pages });
      let array = [];
      for (let i = 0; i < pages; i++) {
        array.push({ value: i + 1 });
      }
      setSteps(array);
    } else {
      setpaginterval({ initial: 0, final: people.length, pages: 0 });
    }
  }, [people]);

  const elapsedTimeFormater = (dueDate) => {
    const min = (Timestamp.now() - dueDate) / 60;
    if (min < 0) {
      return "";
    } else if (min < 60) {
      return <span className="time-stamps">{Math.round(min)} mins ago</span>;
    } else if (min / 60 < 24) {
      return (
        <span className="time-stamps">{Math.round(min / 60)} hrs ago</span>
      );
    } else {
      return (
        <span className="time-stamps">
          {Math.round(min / 60 / 24)} days ago
        </span>
      );
    }
  };

  const disapprove = async (locationNumber, id) => {
    disableButton("dissaprove");
    disableButton("report");
    setAlert({
      state: false,
      message: "",
      type: "alert alert__warning",
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoadingModal(true);
    setModalContent(
      <div className="main-modal-content">
        {" "}
        <h2>Loading</h2>
        <ReactLoading
          type={"bubbles"}
          color={"#9B27AF"}
          height={"50%"}
          width={"50%"}
        />
      </div>
    );
    const request = await fetch(
      `https://us-central1-park7-c5305.cloudfunctions.net/disapprove?locationNumber=${locationNumber}&id=${id}`
    );
    const data = await request.json();
    if (!data) {
      setAlert({
        state: true,
        message: "Sorry, we have an error!",
        type: "alert alert__warning",
      });
    }
    setLoadingModal(false);
    setReloadPeople(!reloadPeople);
  };

  const reportUser = async ({
    locationNumber,
    id,
    plate,
    street,
    house,
    dueDate,
  }) => {
    //here
    disableButton("report");
    disableButton("dissaprove");
    setReportUserModal({ cond: false });
    setAlert({
      state: false,
      message: "",
      type: "alert alert__warning",
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoadingModal(true);
    setModalContent(
      <div className="main-modal-content">
        {" "}
        <h2>Loading</h2>
        <ReactLoading
          type={"bubbles"}
          color={"#9B27AF"}
          height={"50%"}
          width={"50%"}
        />
      </div>
    );

    // Create a reference to 'mountains.jpg'
    const imgsRef = ref(storage, `${plate}`);
    // Create a reference to 'images/mountains.jpg'
    const imgImagesRef = ref(storage, `images/${plate}`);
    const metadata = {
      contentType: "image/jpeg",
      name: `${plate}`,
    };
    // Upload the file and metadata
    const uploadTask = await uploadBytes(
      imgImagesRef,
      reportUserModal.files,
      metadata
    );

    const downloadURL = await getDownloadURL(ref(storage, `images/${plate}`))
    const res = await report({
      locationNumber: locationNumber,
      id: id,
      plate: plate,
      street: street,
      house: house,
      dueDate: dueDate,
      downloadURL:downloadURL,
    });
    if (res.forgiven) {
      setReportModalResult(true);
      setModalContent(
        <>
        <img className="animacion--abuelita" src="/abuelita.gif" alt="" />
        <p>Perdonado!</p>
        <p>veces perdonado:{res.forgiveChancesUsed}</p>
        </>
      );
    } else {
      setReportModalResult(true);
      setModalContent(<h1>no perdonado</h1>);
    }

    /*  if (!res.result) {
      setAlert({
        state: true,
        message: "Sorry, we have an error!",
        type: "alert alert__warning",
      });
    } */

    setLoadingModal(false);
    setReloadPeople(!reloadPeople);
  };

  const reportUserWithoutRegister = async ({
    locationNumber,
    id,
    plate,
    street,
    house,
    img
  }) => {
    setopenReportUserModalNoRegister(false)  
    disableButton("report");
    disableButton("dissaprove");
    setReportUserModal({ cond: false });
    setAlert({
      state: false,
      message: "",
      type: "alert alert__warning",
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoadingModal(true);
    setModalContent(
      <div className="main-modal-content">
        {" "}
        <h2>Loading</h2>
        <ReactLoading
          type={"bubbles"}
          color={"#9B27AF"}
          height={"50%"}
          width={"50%"}
        />
      </div>
    );

    // Create a reference to 'mountains.jpg'
    const imgsRef = ref(storage, `${plate}`);
    // Create a reference to 'images/mountains.jpg'
    const imgImagesRef = ref(storage, `images/${plate}`);
    console.log(imgsRef);
    console.log(imgImagesRef);
    const metadata = {
      contentType: "image/jpeg",
      name: `${plate}`,
    };
    // Upload the file and metadata
    const uploadTask = await uploadBytes(
      imgImagesRef,
      img,
      metadata
    );

    const downloadURL = await getDownloadURL(ref(storage, `images/${plate}`))

    const res = await reportWithoutRegister({
      locationNumber: locationNumber,
      id: id,
      plate: plate,
      street: street,
      house: house,
      downloadURL:downloadURL
    });
    if (res.forgiven) {
      setReportModalResult(true);
      setModalContent(
        <h1>The vehicle has been forgiven!
        <p></p>
        <p>This vehicle has been forgiven {res.forgiveChancesUsed} times</p>
        <p>in the last XX days.</p>
        </h1>
      );
    } else {
      setReportModalResult(true);
      setModalContent(<h1>In violation of parking service terms.</h1>);
    }

    /*  if (!res.result) {
      setAlert({
        state: true,
        message: "Sorry, we have an error!",
        type: "alert alert__warning",
      });
    } */

    setLoadingModal(false);
    setReloadPeople(!reloadPeople);
  };

  const OpenReportUserModal = ({
    plate,
    id,
    locationNumber,
    dueDate,
    street,
    house,
  }) => {
    setReportUserModal({
      cond: true,
      plate: plate,
      id: id,
      locationNumber: locationNumber,
      files: null,
      street: street,
      house: house,
      dueDate: dueDate,
    });
  };

  const ParkingStateSwitch = ({ data }) => {
    let condition;

    if (
      Timestamp.now() < data.dueDate &&
      !data.reported &&
      data.status !== "disapproved"
    ) {
      condition = 1;
    } else if (data.reported) {
      condition = 2;
    } else if (data.status === "disapproved") {
      condition = 3;
    } else if (
      Timestamp.now() > data.dueDate &&
      !data.reported &&
      !data.status !== "disapproved"
    ) {
      condition = 4;
    }

    switch (condition) {
      case 1:
        return <p id="approved">OK</p>;

      case 2:
        return <p id="approved">Reported</p>;

      case 3:
        return <p id="approved">On Hold</p>;

      case 4:
        return (
          <span className="danger-span">
            <div className="danger--div">
              <CgDanger className="danger" size="1.2rem" color="#bb3c4f" />{" "}
              Expired{" "}
            </div>{" "}
            {elapsedTimeFormater(data.dueDate)}
          </span>
        );

      default:
        return <></>;
    }
  };

  const disableButton = (type) => {
    setButtonDesactivated({ ...buttonDesactivated, [type]: true });
    setTimeout(
      () => setButtonDesactivated({ ...buttonDesactivated, [type]: false }),
      5000
    );
  };

  const AlertComponent = () => {
    return alert.state ? (
      <Alert className={alert.type}>
        <p>{alert.message}</p>
      </Alert>
    ) : null;
  };


  return (
    <div className="page-three">
      <AlertComponent />
      <div className="dashboard-3">
        <ParkingWidgetFilter
          setForm={setForm}
          setPeople={setPeople}
          setpaginterval={setpaginterval}
          setCurrentPage={setCurrentPage}
          paginterval={paginterval}
          form={form}
        />
        <button
          className="report--button--noRegister"
          onClick={() =>
            setopenReportUserModalNoRegister(true)
          }
        >
          Report a car
        </button>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="p3-container-2">
          <table>
            <thead>
              <tr>
                <th>License Plate Number</th>
                <th>
                  {" "}
                  <div className="td--container">Status</div>
                </th>
                <th>Operations</th>
              </tr>
            </thead>
            <tbody>
              {people
                .slice(paginterval.initial, paginterval.final)
                .map((value) => {
                  return (
                    <tr key={value.id}>
                      <td className="td">
                        <div className="plate-square">
                          <p className="plate">
                            {String(value.data.hiddenPlate).toUpperCase()}
                          </p>
                        </div>
                      </td>
                      <td>{<ParkingStateSwitch data={value.data} />}</td>

                      <td>
                        {/*boton desaprobar solo funciona en condicion 4*/}
                        {Timestamp.now() > value.data.dueDate &&
                        !value.data.reported &&
                        value.data.status !== "disapproved" ? (
                          <>
                            {/*ERROR, ver si estos parametros se estan inyectando street: value.data.street, house: value.data.house, dueDate:value.data.dueDate */}
                            <button
                              onClick={() =>
                                OpenReportUserModal({
                                  plate: value.data.plate,
                                  id: value.id,
                                  locationNumber: locationNumber,
                                  street: value.data.street,
                                  house: value.data.house,
                                  dueDate: value.data.dueDate,
                                })
                              }
                              style={
                                buttonDesactivated.report ||
                                buttonDesactivated.dissaprove
                                  ? {
                                      pointerEvents: "none",
                                      background: "#9e9e9e",
                                    }
                                  : {}
                              }
                              className="admin--report--button"
                            >
                              Report
                            </button>
                            {/*dissaprove button*/}
                            <button
                              onClick={() =>
                                disapprove(value.data.locationNumber, value.id)
                              }
                              style={
                                buttonDesactivated.report ||
                                buttonDesactivated.dissaprove
                                  ? {
                                      pointerEvents: "none",
                                      background: "#9e9e9e",
                                    }
                                  : {}
                              }
                              className="admin--dissaprove--button"
                            >
                              Put on hold expired status
                            </button>
                          </>
                        ) : (
                          <p id="approved">no operations</p>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <PaginationParkingWidget
          steps={steps}
          setpaginterval={setpaginterval}
          setCurrentPage={setCurrentPage}
          paginterval={paginterval}
          setStepsShrink={setStepsShrink}
          currentPage={currentPage}
          stepsShrink={stepsShrink}
        />
      </div>

      <GenericModal state={loadingModal} setState={""}>
        {modalContent}
      </GenericModal>

      <GenericModal state={reportModalResult} setState={setReportModalResult}>
        {modalContent}
      </GenericModal>

      {reportUserModal.cond ? (
        <ReportModal
          state={reportUserModal}
          setState={setReportUserModal}
          reportUser={reportUser}
        />
      ) : null}


     
      {openReportUserModalNoRegister? (
        <ReportModalNoRegister
          locationNumber={locationNumber}
          reportUserWithoutRegister={reportUserWithoutRegister}
          closeModalState = {openReportUserModalNoRegister}
          setcloseModalState = {setopenReportUserModalNoRegister}
        />
      ) : null}
    </div>
  );
};

export default ParkingWidget;
