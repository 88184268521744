import ModalBase from "../../../UIComponents/ModalBase"
import { useState } from "react";


const ReportModalNoRegister = ({ reportUserWithoutRegister, locationNumber,closeModalState, setcloseModalState}) => {
const [state, setState] = useState({ cond: false,
  plate: "",
  street: "",
  house: "",
  files: null,})
  const imgHandleChange = async ({target}) => {
    setState({...state ,files: target.files[0]})
  }

  const handleChange = ({ target }) => {
    setState((state) => ({
      ...state,
      [target.name]: target.value,
    }));
   
  };

  return (
    <>
        { <div className="main-modal-component"><ModalBase close={()=>setcloseModalState(!closeModalState)} />

        <div id="modal1" className="main-modal">
          <div className="main-modal-content">
          <div className="report--modal--content padding">
    <h1 id="report--modal--title">Report a car</h1>
    <div className="form--field">
        <input id = 'inputPlate' className="inputPlate" placeholder="Plate" value={state.plate} type= "text" name="plate" onChange={handleChange} />
        <input id = 'inputHouse' className="inputHouse" placeholder="House" value={state.house} type= "number" name="house" onChange={handleChange} />
        <input id = 'inputStreet' className="inputStreet" placeholder="Street" value={state.street} type= "number" name="street" onChange={handleChange} />
    </div>
    
    <label className="img--label" htmlFor="inputImg">
    {state.files === null? "Upload image" : "Done!"}
    <input id = 'inputImg' className="inputImg" type= "file" accept=".jpg" name="carimg" onChange={imgHandleChange} />
    </label>  
   
   <div className="spacer"></div>
    <button className="Button" style= {state.files === null? {backgroundColor: '#9e9e9e',pointerEvents: 'none'} : {} } onClick={() =>reportUserWithoutRegister({locationNumber:locationNumber, id:state.id, plate:state.plate, street:state.street, house:state.house, img: state.files })}>Report</button>
    </div>   
        </div>
        </div>
        




         </div> }
    </>
  );
};

export default ReportModalNoRegister

