import db from "../config";
import { useState } from "react";
import { app } from "../config";
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  orderBy,
  onSnapshot,
} from "@firebase/firestore";
import ReportModal from "./admin/parkingWidget/ReportModal";
import ReportModalNoRegister from "./admin/parkingWidget/ReportModalNoRegister";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import NavBar from "../UIComponents/NavBars/NavBar";
import Modal from "../UIComponents/Modal";
import GenericModal from "../UIComponents/GenericModal";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAuth, signOut } from "@firebase/auth";
import TransferPasses from "../Views/TransferPasses";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa/";
import { CgDanger } from "react-icons/cg/";
import { report, reportWithoutRegister } from "../../components/Firebase/Firebase";
import ReactLoading from "react-loading";
import Alert from "../UIComponents/Alert";

const Dashboard = () => {
  const [people, setPeople] = useState([]);
  const [paginterval, setpaginterval] = useState({
    initial: 0,
    final: 0,
    pages: 0,
  });
  const [steps, setSteps] = useState([{ value: 1 }]);
  const [stepsShrink, setStepsShrink] = useState({ initial: 0, final: 5 });
  const [currentPage, setCurrentPage] = useState(1);
  const [passes, setPasses] = useState(0);
  const [form, setForm] = useState({ search: "", selector: "house" });
  const [modalContent, setModalContent] = useState(null);
  const [transferModal, setTransferModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({email: "", state:false});
  const [loadingModal, setLoadingModal] = useState(false);
  const [reportModalResult, setReportModalResult] = useState(false);
  const [reloadPeople, setReloadPeople] = useState(false);
  const [alert, setAlert] = useState({
    state: false,
    message: "",
    type: "alert alert__warning",
  });
  const [buttonDesactivated, setButtonDesactivated] = useState({
    report: false,
    dissaprove: false,
  });
  const params = useParams();
  let userLog = true;

    // los estados deben ser estados globales que almacenen la placa y el carro a reportar deben ser globales
    const [reportUserModal, setReportUserModal] = useState({
      cond: false,
      plate: "",
      files: null,
    });
  
    const [openReportUserModalNoRegister, setopenReportUserModalNoRegister] = useState(false)

    const firebaseConfig = {
      // ...
      storageBucket: "gs://park7-c5305.appspot.com",
    };
    // Initialize Cloud Storage and get a reference to the service
    const storage = getStorage(app);
    const storageRef = ref(storage, "images");

  useEffect(() => {
    if (currentUser) {
      const q = query(
        collection(db, `Locations/${params.locationNumber}/userPersonalInfo`),
        where("email", "==", currentUser.email)
      );
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((document) => {
          setPasses(document.data().passes);
        });
      });
    }
  }, [currentUser.email]);

  useEffect(() => {
    getAuth(app).onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser({email: user.email, state:true});
        const q = query(
          collection(db, `Locations/${params.locationNumber}/parking`),
          orderBy("date", "desc")
        );
        onSnapshot(q, (querySnapshot) => {
          const parking = [];
          querySnapshot.forEach((doc) => {
            parking.push({ id: doc.id, data: doc.data() });
          });
          setPeople(parking);
        });
      } else if (!user && userLog) {
        window.location.href = "/login";
      }
    });
  }, []);

  const handleChange = ({ target }) => {
    setForm((state) => ({
      ...state,
      [target.name]: target.value,
    }));
  };

  const Filter = async () => {
    const querySnapshot = await getDocs(
      query(
        collection(db, `Locations/${params.locationNumber}/parking`),
        where(form.selector, "==", form.search),
        orderBy("date", "desc")
      )
    );
    const Array = [];
    querySnapshot.forEach((doc) => {
      Array.push({ data: doc.data(), id: doc.id });
    });
    setPeople(Array);
    changePag(1);
  };

  const Out = () => {
    userLog = false;
    signOut(getAuth(app));
    window.location.href = "/login";
  };

  const showTransferModal = () => {
    if (passes > 0) {
      setModalContent(
        <TransferPasses
          passes={passes}
          locationNumber={params.locationNumber}
          currentUser={currentUser.email}
          setModalContent={setModalContent}
        />
      );
      setTransferModal(true);
    } else if (passes === 0) {
      setModalContent(<h3>You don't have parking passes to transfer</h3>);
    }
  };

  //pagination
  useEffect(() => {
    if (people.length > 7) {
      const pages = Math.ceil(people.length / 7);
      setpaginterval({ initial: 0, final: 7, pages: pages });
      let array = [];
      for (let i = 0; i < pages; i++) {
        array.push({ value: i + 1 });
      }
      setSteps(array);
    } else {
      setpaginterval({ initial: 0, final: people.length, pages: 0 });
    }
  }, [people]);

  const changePag = (number) => {
    setpaginterval({
      ...paginterval,
      initial: 7 * number - 7,
      final: 7 * number,
    });
    setCurrentPage(number);
  };
  // 🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧




  const reportUser = async ({
    locationNumber,
    id,
    plate,
    street,
    house,
    dueDate,
  }) => {
    //here
    disableButton("report");
    disableButton("dissaprove");
    setReportUserModal({ cond: false });
    setAlert({
      state: false,
      message: "",
      type: "alert alert__warning",
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoadingModal(true);
    setModalContent(
      <div className="main-modal-content">
        {" "}
        <h2>Loading</h2>
        <ReactLoading
          type={"bubbles"}
          color={"#9B27AF"}
          height={"50%"}
          width={"50%"}
        />
      </div>
    );

    // Create a reference to 'mountains.jpg'
    const imgsRef = ref(storage, `${plate}`);
    // Create a reference to 'images/mountains.jpg'
    const imgImagesRef = ref(storage, `images/${plate}`);
    const metadata = {
      contentType: "image/jpeg",
      name: `${plate}`,
    };
    // Upload the file and metadata
    const uploadTask = await uploadBytes(
      imgImagesRef,
      reportUserModal.files,
      metadata
    );

    const downloadURL = await getDownloadURL(ref(storage, `images/${plate}`))
    const res = await report({
      locationNumber: locationNumber,
      id: id,
      plate: plate,
      street: street,
      house: house,
      dueDate: dueDate,
      downloadURL:downloadURL,
    });
    if (res.forgiven) {
      setReportModalResult(true);
      setModalContent(
        <>
        <img className="animacion--abuelita" src="/abuelita.gif" alt="" />
        <p>Thank you for reporting!</p>
        <p></p>
        <p>However, this time</p>
        <p>the vehicle has been forgiven.</p>
        <p></p>
        <p>This location gives their users </p>
        <p>XXX chances every XX days</p>
        <p>to be forgiven for parking violations.</p>
        <p></p>
        <p>This vehicle has been forgiven {res.forgiveChancesUsed} times</p>
        <p>in the last XX days.</p>
        </>
      );
    } else {
      setReportModalResult(true);
      setModalContent(
      <>
      <h1>no perdonado</h1>
      <img className="animacion--abuelita" src="/abuelita.gif" alt="" />
        <p>Thank you for reporting!</p>
        <p></p>
        <p>As soon as the enforcement service provider</p>
        <p>is successful in fulfilling this claim,</p>
        <p>you will receive a confirmation e-mail</p>
        <p>including the value that will be credited</p>
        <p>to your account after the process is completed.</p>
      </>
      );
    }

    /*  if (!res.result) {
      setAlert({
        state: true,
        message: "Sorry, we have an error!",
        type: "alert alert__warning",
      });
    } */

    setLoadingModal(false);
    setReloadPeople(!reloadPeople);
  };

  const reportUserWithoutRegister = async ({
    locationNumber,
    id,
    plate,
    street,
    house,
    img
  }) => {
    setopenReportUserModalNoRegister(false)  
    disableButton("report");
    disableButton("dissaprove");
    setReportUserModal({ cond: false });
    setAlert({
      state: false,
      message: "",
      type: "alert alert__warning",
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoadingModal(true);
    setModalContent(
      <div className="main-modal-content">
        {" "}
        <h2>Loading</h2>
        <ReactLoading
          type={"bubbles"}
          color={"#9B27AF"}
          height={"50%"}
          width={"50%"}
        />
      </div>
    );

    // Create a reference to 'mountains.jpg'
    const imgsRef = ref(storage, `${plate}`);
    // Create a reference to 'images/mountains.jpg'
    const imgImagesRef = ref(storage, `images/${plate}`);
    console.log(imgsRef);
    console.log(imgImagesRef);
    const metadata = {
      contentType: "image/jpeg",
      name: `${plate}`,
    };
    // Upload the file and metadata
    const uploadTask = await uploadBytes(
      imgImagesRef,
      img,
      metadata
    );

    const downloadURL = await getDownloadURL(ref(storage, `images/${plate}`))

    const res = await reportWithoutRegister({
      locationNumber: locationNumber,
      id: id,
      plate: plate,
      street: street,
      house: house,
      downloadURL:downloadURL
    });
    if (res.forgiven) {
      setReportModalResult(true);
      setModalContent(
        <h1>Is being pardoned! pardoned:{res.forgiveChancesUsed} times</h1>
      );
    } else {
      setReportModalResult(true);
      setModalContent(<h1>no perdonado</h1>);
    }

    /*  if (!res.result) {
      setAlert({
        state: true,
        message: "Sorry, we have an error!",
        type: "alert alert__warning",
      });
    } */

    setLoadingModal(false);
    setReloadPeople(!reloadPeople);
  };

  const OpenReportUserModal = ({
    plate,
    id,
    locationNumber,
    dueDate,
    street,
    house,
  }) => {
    setReportUserModal({
      cond: true,
      plate: plate,
      id: id,
      locationNumber: locationNumber,
      files: null,
      street: street,
      house: house,
      dueDate: dueDate,
    });
  };




  const next = (number) => {
    setpaginterval({
      ...paginterval,
      initial: 7 * number - 7,
      final: 7 * number,
    });
    setCurrentPage(number);
    if (number < 6) {
      setStepsShrink({ initial: 0, final: 5 });
    } else {
      setStepsShrink({ initial: number - 1, final: number + 4 });
    }
  };

  const ElapsedTimeFormater = (dueDate) => {
    const min = (Timestamp.now() - dueDate) / 60;
    if (min < 0) {
      return "";
    } else if (min < 60) {
      return <span className="time-stamps">{Math.round(min)} mins ago</span>;
    } else if (min / 60 < 24) {
      return (
        <span className="time-stamps">{Math.round(min / 60)} hrs ago</span>
      );
    } else {
      return (
        <span className="time-stamps">
          {Math.round(min / 60 / 24)} days ago
        </span>
      );
    }
  };

  const AlertComponent = () => {
    return alert.state ? (
      <Alert className={alert.type}>
        <p>{alert.message}</p>
      </Alert>
    ) : null;
  };

  const ParkingStateSwitch = ({ data }) => {
    let condition;

    if (
      Timestamp.now() < data.dueDate &&
      !data.reported &&
      data.status !== "disapproved"
    ) {
      condition = 1;
    } else if (data.reported) {
      condition = 2;
    } else if (data.status === "disapproved") {
      condition = 3;
    } else if (
      Timestamp.now() > data.dueDate &&
      !data.reported &&
      !data.status !== "disapproved"
    ) {
      condition = 4;
    }

    switch (condition) {
      case 1:
        return <p id="approved">OK</p>;

      case 2:
        return <p id="approved">Reported</p>;

      case 3:
        return <p id="approved">On Hold</p>;

      case 4:
        return (
          <span className="danger-span">
            <div className="danger--div">
              <CgDanger className="danger" size="1.2rem" color="#bb3c4f" />{" "}
              Expired{" "}
            </div>{" "}
            {ElapsedTimeFormater(data.dueDate)}
          </span>
        );

      default:
        return <></>;
    }
  };

  const disableButton = (type) => {
    setButtonDesactivated({ ...buttonDesactivated, [type]: true });
    setTimeout(
      () => setButtonDesactivated({ ...buttonDesactivated, [type]: false }),
      5000
    );
  };

  return (
    <div className="page-three">
      <NavBar
        email={currentUser.email}
        Out={Out}
        showTransferModal={showTransferModal}
        passes={passes}
      />
       <AlertComponent />
      <div className="dashboard-3">
      <button
          className="report--button--noRegister--mobile"
          onClick={() =>
            setopenReportUserModalNoRegister(true)
          }
        >
          Report a car
        </button>
        <div className="p3-container-1">
          <h1>Parking History</h1>

          <div className="p3-search">
            <p>Filter</p>

            <select
              onChange={handleChange}
              name="selector"
              value={form.selector}
            >
                <option value="street">Street</option>
                <option value="plate">License Plate</option>
            </select>

            <input name="search" onChange={handleChange} value={form.search} />
            <button onClick={Filter}>Search</button>
            <button
          className="report--button--noRegister"
          onClick={() =>
            setopenReportUserModalNoRegister(true)
          }
        >
          Report a car
        </button>
          </div>
        </div>

        <Modal
          handleChange={handleChange}
          Filter={Filter}
          form={form}
          setForm={setForm}
        />

        <div className="p3-container-2">
          <table>
            <thead>
              <tr>
                <th>License Plate</th>
                <th>
                  {" "}
                  <div className="td--container">Status</div>
                </th>
                <th>Operations</th>
              </tr>
            </thead>
            <tbody>
              {people
                .slice(paginterval.initial, paginterval.final)
                .map((value) => {
                  return (
                    <tr key={value.id}>
                      <td className="td">
                        <div className="plate-square">
                          <p className="plate">
                            {String(value.data.hiddenPlate).toUpperCase()}
                          </p>
                        </div>
                      </td>
                      <td>{<ParkingStateSwitch data={value.data} />}</td>

                      <td>
                        {/*boton desaprobar*/}
                        {Timestamp.now() > value.data.dueDate &&
                        !value.data.reported &&
                        value.data.status !== "disapproved" ? (
                          <button
                          style={
                                buttonDesactivated.report
                                  ? {
                                      pointerEvents: "none",
                                      background: "#9e9e9e",
                                    }
                                  : {}
                              }
                              onClick={() =>
                                OpenReportUserModal({
                                  plate: value.data.plate,
                                  id: value.id,
                                  locationNumber: params.locationNumber,
                                  street: value.data.street,
                                  house: value.data.house,
                                  dueDate: value.data.dueDate,
                                })
                              }
                            className="report--button"
                          >
                            Report
                          </button>
                        ) : (
                          <p id="approved">no operations</p>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button
            onClick={() => next(currentPage - 1)}
            className="pagination-navigation"
            style={
              currentPage === 1
                ? { pointerEvents: "none" }
                : { cursor: "pointer" }
            }
          >
            <FaArrowLeft
              size="1rem"
              color={currentPage === 1 ? "#bebebe" : "#6A0080"}
            />
          </button>
          {steps.slice(stepsShrink.initial, stepsShrink.final).map((step) => {
            return (
              <button
                key={step.value}
                className="pagination-numbers"
                style={
                  currentPage === step.value
                    ? { backgroundColor: "#6A0080", color: "#fff" }
                    : null
                }
                onClick={() => changePag(step.value)}
              >
                {" "}
                {step.value}
              </button>
            );
          })}

          <button
            onClick={() => next(currentPage + 1)}
            className="pagination-navigation"
            style={
              currentPage >= paginterval.pages
                ? { pointerEvents: "none" }
                : { cursor: "pointer" }
            }
          >
            <FaArrowRight
              size="1rem"
              color={currentPage >= paginterval.pages ? "#bebebe" : "#6A0080"}
            />
          </button>
        </div>
      </div>

      <GenericModal state={transferModal} setState={setTransferModal}>
        {modalContent}
      </GenericModal>
      <GenericModal state={loadingModal} setState={""}>
        {modalContent}
      </GenericModal>
      <GenericModal state={reportModalResult} setState={setReportModalResult}>
        {modalContent}
      </GenericModal>

      {reportUserModal.cond ? (
        <ReportModal
          state={reportUserModal}
          setState={setReportUserModal}
          reportUser={reportUser}
        />
      ) : null}


     
      {openReportUserModalNoRegister? (
        <ReportModalNoRegister
          locationNumber={params.locationNumber}
          reportUserWithoutRegister={reportUserWithoutRegister}
          closeModalState = {openReportUserModalNoRegister}
          setcloseModalState = {setopenReportUserModalNoRegister}
        />
      ) : null}
    </div>
  );
};

export default Dashboard;
