import {
  addParking,
} from "../../Firebase/Firebase";
import { useState, useEffect } from "react";
import PublicNavBar from "../../UIComponents/NavBars/PublicNavBar";
import Alert from "../../UIComponents/Alert";
import { AiFillCheckCircle } from "react-icons/ai/";
import PersonalInfoWithCode from "./PersonalInfoWithCode";
import { useParams } from "react-router-dom";
import ReactLoading from 'react-loading';
import GenericModal from "../../UIComponents/GenericModal";
import DataForm from "./DataForm";

const GeneralFormWithCode = () => {
  const [state, setState] = useState({
    locationNumber: "",
    street: "",
    house: "",
    unit: "",
    plate: "",
    email: "",
    phone: "",
    vehicleColor: "#ff0000",
    vehicleYear: "",
    vehicleMake: "",
  });

  const [step, setStep] = useState(1);
  const [policiesCheck, setPoliciesCheck] = useState({ check: false });
  const [submitButton, setSubmitButton] = useState(false);
  const [alert, setAlert] = useState({ state: false, message: " " });
  const [modalContent, setModalContent] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const params = useParams();

  useEffect(() => {
    setState({
      ...state,
      locationNumber: params.locationNumber,
      street: params.street,
      house: params.house,
      unit: params.unit,
    });
  }, []);

  const personalInfoHandleChange = async ({ target }) => {
    setState((state) => ({
      ...state,
      [target.name]: target.value.replace(/\s/g, ""),
    }));
    console.log(state);
  };
  const policiesCheckHandleChange = async ({ target }) => {
    setPoliciesCheck(() => ({
      [target.name]: target.checked,
    }));
    await activateButtonSubmit();
  };

  const activateButtonSubmit = async () => {
    if (!policiesCheck.check) {
      setSubmitButton(true);
      return true;
    } else {
      setSubmitButton(false);
      return false;
    }
  };

  const submit = async () => {
    setLoadingModal(true)
    setModalContent(<div className="main-modal-content"> <h2>Loading</h2><ReactLoading type={"bubbles"} color={"#9B27AF"} height={"50%"} width={"50%"} /></div>)
    const conditions =
      state.locationNumber === "" ||
      state.street === "" ||
      state.house === "" ||
      state.unit === "" ||
      state.plate === "" ||
      state.plate.length > 14 ||
      state.email === "" ||
      state.email.indexOf("@") === -1;
    if (!conditions && policiesCheck) {
      
      const addTodb = await addParking(
        {
          locationNumber: state.locationNumber,
          plate: state.plate,
          email: state.email,
          house: state.house,
          street: state.street,
          unit: state.unit,
          phone: state.phone,
          vehicleColor: state.vehicleColor,
          vehicleYear: state.vehicleYear,
          vehicleMake: state.vehicleMake,
      }
      );

      console.log(addTodb)
      if (addTodb.status) {
        setAlert({ state: false, message: "" });
        setState({
          locationNumber: "",
          street: "",
          house: "",
          unit: "",
          plate: "",
          email: "",
          phone: "",
          vehicleColor: "#ff0000",
          vehicleYear: "",
          vehicleMake: "",
        });
        setPoliciesCheck({ check: false });
        setStep(3);
      } else {
        setAlert({ state: true, message: addTodb.error });
      }
    } else {
      setAlert({ state: true, message: "error please check the fields" });
    }
  };

 
  const AlertComponent = () => {
    return alert.state ? (
      <Alert className="alert alert__warning">
        <p>{alert.message}</p>
      </Alert>
    ) : null;
  };

  const nextStep = () => {
    if (step <= 5) {
      setStep(step + 1);
      setAlert({ state: false, message: "" });
    } else {
      return null;
    }
    console.log(step + 1);
  };

  switch (step) {
    case 1:
      return (
        <>
        <PublicNavBar />
        <AlertComponent />
        <div className="generalForm--container">
        <PersonalInfoWithCode
          vehicleColor={state.vehicleColor}
          plate={state.plate}
          personalInfoHandleChange={personalInfoHandleChange}
          policiesCheckHandleChange={policiesCheckHandleChange}
          policiesCheck={policiesCheck}
          submitButton={submitButton}
          nextStep={nextStep}
        />
        </div>
        <GenericModal state={loadingModal} setState={""}>
        {modalContent}
      </GenericModal>
        </>
      );

      case 2:
        return (
          <>
            <PublicNavBar />
            <div className="generalForm--container">
                <DataForm
                email={state.email}
                plate={state.plate}
                phone={state.phone}
                vehicleMake= {state.vehicleMake}
                vehicleColor={state.vehicleColor}
                vehicleYear = {state.vehicleYear}
                personalInfoHandleChange={personalInfoHandleChange}
                policiesCheckHandleChange={policiesCheckHandleChange}
                policiesCheck={policiesCheck}
                submitButton={submitButton}
                nextStep={nextStep}
                submit={submit}
                />
            </div>
          </>
        );

    case 3:
      return (
        <>
        <PublicNavBar />
        <div className="generalForm--container">
          <div className="success--card">
            <AiFillCheckCircle size="5rem" color="#198754" />
            <h1>Success!</h1>
            <button
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Go back
            </button>
          </div>
        </div>
      </>
    );
    default:
      return null;
  }
};

export default GeneralFormWithCode;
