import { app } from "../config";
import db from "../config";
import {
  getDoc,
  doc,
} from "@firebase/firestore";
import { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
} from "@firebase/auth";
import AbsoluteNavBar from "../UIComponents/NavBars/AbsoluteNavBar";
import Alert from "../UIComponents/Alert";

const Login = () => {
  const [form, setform] = useState({
    user: "",
    password: "",
    locationNumber: "",
  });

  const [isActive, setIsActive] = useState(false);
  const [alert, setAlert] = useState({state: false,message: " "});

  const handleChange = ({ target }) => {
    setform((state) => ({
      ...state,
      [target.name]: target.value,
    }));
  };

  let emailValidated;
  const toggleEmail = (value) => {
    emailValidated = value;
  };

  useEffect(() => {
    if (
      form.user.length === 0 ||
      form.password.length === 0 ||
      form.locationNumber.length === 0 ||
      !emailValidated
    ) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [form]);

  const AlertComponent = () => {
    return alert.state ? (
      <Alert className="alert alert__warning">
        <p>{alert.message}</p>
      </Alert>
    ) : null;
  };

  const submit = async () => {


    const docRef = doc(db, `Locations/${form.locationNumber}`);
    const docSnap = await getDoc(docRef);
if (docSnap.exists()) {
  signInWithEmailAndPassword(getAuth(app), form.user, form.password)
      .then(() => {
        setAlert({state:false,message: ""});
        onAuthStateChanged(getAuth(app), (user) => {
          if (user) {
            window.location.href = `dashboard/${form.user}/${form.locationNumber}`;
          }
        });
      })
      .catch((error) => {
        setAlert({state:true, message: "Error, please check username or password"});
        console.log(error);
      });
} else {
  setAlert({state:true,message: "Bad Location Number"});
}


   /*  
 */
  };

  return (
    <div className="Page_2">
      <div className="alertContainer">
        <AlertComponent />
      </div>
      <AbsoluteNavBar className="navBar navBar__light" />
      <div className="filterTwo">
        <div className="Page2--container">
          <div className="Page2--card">
            <div className="card2--content">
              <h1 className="Page2--Tittle">Login</h1>

              <div className="form--field">
                <p>Location Number</p>
                <input
                  type="text"
                  className="username"
                  name="locationNumber"
                  onChange={handleChange}
                  value={form.locationNumber}
                />
                {form.user.indexOf("@") === -1 && form.user.length > 0
                  ? toggleEmail(false)
                  : toggleEmail(true)}
              </div>

              <div className="form--field">
                <p>Email</p>
                <input
                  type="email"
                  className="username"
                  name="user"
                  onChange={handleChange}
                  value={form.user}
                />
                {form.user.indexOf("@") === -1 && form.user.length > 0
                  ? toggleEmail(false)
                  : toggleEmail(true)}
              </div>

              <div className="form--field">
                <p>Password </p>
                <input
                  type="password"
                  className="password"
                  name="password"
                  onChange={handleChange}
                  value={form.password}
                />
              </div>
              <button
                className={
                  isActive
                    ? "login--btn"
                    : "login--btn login--btn__desactivated"
                }
                onClick={submit}
              >
                Submit
              </button>
              <a className="forgotPassword" href="">
                Forgot password
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
