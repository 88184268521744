import React from "react";
import VehicleSelector from "./AuxFunctions/VehicleSelector";
import DatePicker from 'react-date-picker';

const DataForm = ({
    email,
    vehicleYear,
    phone,
    vehicleMake,
    personalInfoHandleChange,
    submitButton,
    nextStep,
    previousStep,
    submit,
}) => {
    /*   const [check, setCheck] = useState({check: false});
    
    const checkHandleChange = ({ target }) => {
        setCheck(() => ({
            [target.name]: target.checked,
        }));
    }; */
    
    let ValuePiece = Date | null;
    let Value = ValuePiece | [ValuePiece, ValuePiece];

  return (
    <div className="info--container">
      <div className="info--card">
        <h1>Recomended</h1>
        <div className="field">
          <p>Email </p>
          <input
            className="info--input"
            name="email"
            onChange={personalInfoHandleChange}
            value={email}
          />
        </div>
        <div className="field">
          <p>Phone</p>
          <input
            className="info--input"
            name="phone"
            onChange={personalInfoHandleChange}
            value={phone}
          />
        </div>
        <div className="field">
          <p>Vehicle Make</p>
          <VehicleSelector personalInfoHandleChange={personalInfoHandleChange} vehicleMake = {vehicleMake}/>
        </div>
        <div className="field">
          <p>Vehicle Year</p>
            <input className="info--input" type="number" min="1900" max="2099" name="vehicleYear" id="" value={vehicleYear} onChange={personalInfoHandleChange} />
        </div>
        <button
          onClick={submit}
          className={"Button"}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default DataForm;
