import { HiMenu } from "react-icons/hi/";
import { CgClose } from "react-icons/cg/";
import { AiFillCaretDown } from "react-icons/ai/";
import { AiFillCaretUp } from "react-icons/ai/";
import { useState } from "react";
import { motion } from "framer-motion";

const NavLinks = (props) => {
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };
  return (
    <>
      <ul className="navList">
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.05 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="#" onClick={() => props.showTransferModal()}>Transfer</a>
        </motion.li>

        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="/#">Home</a>
        </motion.li>

        <motion.li
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.1 }}
              onClick={() => props.isMobile && props.closeMobileMenu()}
            >
              <a href="#" onClick={()=>props.Out()}>Exit</a>
            </motion.li>

      </ul>
    </>
  );
};

const DropNavLinks = (props) => {

  const [enabled, setEnabled]= useState(false)

  const toggle = (enabled) => {
    if (!enabled) {
      setEnabled(true)
    }
    else {
      setEnabled(false)
    }
  }

  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };
  return (
    <>
      <ul className="navList">
        <div className="dropdown">
          <div onClick={()=>toggle(enabled)} className="drop--inline">
            <button className={!enabled? "dropbtn" : "dropbtn dropbtn__active"}>{props.email}</button>
            <AiFillCaretDown className={!enabled? "drop" : "drop drop__hidden" } size="1em" color="white" />
            <AiFillCaretUp className={enabled? "dropUp" : "dropUp dropUp__hidden" } size="1em" color="white" />
          </div>
          <div className={enabled ? `dropdown-content dropdown-content__active` : "dropdown-content dropdown-content__inactive" }>
            <motion.li
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.05 }}
              onClick={() => props.isMobile && props.closeMobileMenu()}
            >
              <a href="#" onClick={()=>props.showTransferModal()}>Transfer</a>
            </motion.li>

            <motion.li
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.1 }}
              onClick={() => props.isMobile && props.closeMobileMenu()}
            >
              <a href="/#">Home</a>
            </motion.li>
       
            <motion.li
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.1 }}
              onClick={() => props.isMobile && props.closeMobileMenu()}
            >
              <a href="#" onClick={()=>props.Out()}>Exit</a>
            </motion.li>
          </div>
        </div>
      </ul>
    </>
  );
};

const Navigation = ({ email, Out, showTransferModal, passes }) => {
  return (
    <nav className="navBar">
      {/*  <img className ="logo" src="/Fomofood.png" alt="" /> */}
      <div className="nav--container-logo"><h2>Logo</h2></div>
      <div className="nav--container-passes"><h4>Available Passes: {passes}</h4></div>
      <div className="nav--container-menu"><DropNavLinks email={email} Out = {Out} showTransferModal = {showTransferModal} /></div>
    </nav>
  );
};

const NavigationMobile = ({Out,passes, showTransferModal}) => {
  const [toggle, setToggle] = useState(false);

  const hamburguerIcon = (
    <HiMenu
      className="hamburguer"
      size="2.5em"
      color="white"
      onClick={() => setToggle(!toggle)}
    />
  );
  const closeIcon = (
    <CgClose
      className="hamburguer"
      size="2em"
      color="white"
      onClick={() => setToggle(!toggle)}
    />
  );

  const closeMobileMenu = () => setToggle(false);

  return (
    <nav className="navBarMobile">
      {/*  <img className ="mlogo" src="/Fomofood.png" alt="" /> */}
      <div className="nav--container-logo"><h2>Logo</h2> </div>
      <div className="nav--container-passes"><h4>Passes: {passes}</h4> </div>
      <div className="nav--container-menu">
      {toggle ? closeIcon : hamburguerIcon}
      {toggle && <NavLinks Out = {Out} isMobile={true} closeMobileMenu={closeMobileMenu} showTransferModal = {showTransferModal} />}
      </div>
    </nav>
  );
};

const NavBar = ({ email, Out, showTransferModal, passes }) => {
  return (
    <>
      <Navigation email={email} Out = {Out} showTransferModal = {showTransferModal} passes = {passes} />
      <NavigationMobile Out = {Out} passes = {passes} showTransferModal = {showTransferModal} />
    </>
  );
};

export default NavBar;
