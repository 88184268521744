import TextInput from "react-autocomplete-input";

const StreetForm = ({
  streets,
  street,
  streetHandleChange,
  nextStep,
  houseHandler,
  setAlert
}) => {

  const makeHouseHandler = () => {
    for (let i = 0; i < streets.length; i++) {
      if (streets[i].street === street){
        houseHandler();
        nextStep();
        break
      } else {

        setAlert({state: true,message: "Bad street"})
      }
  }
  }  

  const handle = (target) => {
    streetHandleChange(target);
  };

  return (
    <div>
      <div className="street--card">
        <h1>Name of the Street where the house your are visiting is located</h1>
        <TextInput
          rows="1"
          className="input"
          onChange={streetHandleChange}
          value={street}
          onSelect={handle}
          trigger={["", " "]}
          options={streets.map((st) => st.street)}
        />
        <button className="Button" onClick={() => makeHouseHandler()}>
          search
        </button>
      </div>
    </div>
  );
};

export default StreetForm;
