import {
  addPassWithEmail,
  discountPassWithEmail,
  getUserPersonalDataByEmail,
  createUser,
  getAllUsers,
  deleteUser,
} from "../../Firebase/Firebase";
import { hex_sha1 } from "../../Views/ParkForms/AuxFunctions/Sha1";
import { useState, useEffect } from "react";
import JSONtoXLS from "./JSONtoXLS";
import GenericModal from "../../UIComponents/GenericModal";
import ReactLoading from "react-loading";
import { AiFillCheckCircle } from "react-icons/ai/";
import Alert from "../../UIComponents/Alert";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa/";

const UserWidget = ({ locationNumber, settedPasses }) => {
  const [pass, setpass] = useState({ quantity: null, email: "", passes: null });
  const [user, setuser] = useState({
    email: "",
    street: "",
    house: "",
    unit: "",
  });
  const [modalContent, setModalContent] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [steps, setSteps] = useState([{ value: 1 }]);
  const [stepsShrink, setStepsShrink] = useState({ initial: 0, final: 5 });
  const [currentPage, setCurrentPage] = useState(1);
  const [paginterval, setpaginterval] = useState({
    initial: 0,
    final: 0,
    pages: 0,
  });
  const [buttonDesactivated, setButtonDesactivated] = useState({delete: false, excel: false, addSub: false, create:false});
  const [excelModal, setExcelModal] = useState(false);
  const [excelData, setExcelData] = useState({ excelData: null });
  const [userData, setuserData] = useState([]);
  const [alert, setAlert] = useState({ state: false, message: "", type:"alert alert__warning" });
  

  useEffect(async () => {
    const data = await getAllUsers(locationNumber);
    setuserData(data);
  }, [loadingModal]);

  useEffect(async () => {
    if (userData.length > 7) {
      const pages = Math.ceil(userData.length / 7);
      setpaginterval({ initial: 0, final: 7, pages: pages });
      let array = [];
      for (let i = 0; i < pages; i++) {
        array.push({ value: i + 1 });
      }
      setSteps(array);
    } else {
      setpaginterval({ initial: 0, final: userData.length, pages: 0 });
    }
  }, [userData]);


  const makeid = (length) => {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const passHandleChange = ({ target }) => {
    setpass({
      ...pass,
      [target.name]: target.value,
    });
  };

  const addHandleChange = ({ target }) => {
    setuser({
      ...user,
      [target.name]: target.value,
    });
  };

  const excelHandleChange = async ({ target }) => {
     setExcelData({
      [target.name]: target.files ? target.files[0] : null,
    })
    setExcelModal(false)
  };

  const add = async () => {
    if(pass.email === "" || !pass.quantity ) {
      setAlert({
        state: true,
        message: "Please check fields",
        type: "alert alert__warning"
      });
      return 
    }
    disableButton("addSub")
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setModalContent(
      <div className="main-modal-content">
        {" "}
        <h2>Loading</h2>
        <ReactLoading
          type={"bubbles"}
          color={"#9B27AF"}
          height={"50%"}
          width={"50%"}
        />
      </div>
    );
    setLoadingModal(true);
    const added = await addPassWithEmail(
      locationNumber,
      pass.email,
      pass.quantity
    );
    if (added.result) {
      setAlert({
        state: false,
        message: "",
        type: "alert alert__warning"
      });
      const user = await getUserPersonalDataByEmail(locationNumber, pass.email);
      setpass({ ...pass, passes: user.passes });
      setLoadingModal(false);
  
      setSuccessModal(true);
      setModalContent(
        <>
          {" "}
          <AiFillCheckCircle size="5rem" color="#198754" />
          <h1>Success!</h1>
        </>
      );
    }
    else {
      setLoadingModal(false);
      setAlert({
        state: true,
        message: added.error,
        type: "alert alert__warning"
      });
    }
  };

  const substract = async () => {
    if(pass.email === "" || !pass.quantity ) {
      setAlert({
        state: true,
        message: "Please check fields",
        type: "alert alert__warning"
      });
      return 
    }
    disableButton("addSub")
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setModalContent(
      <div className="main-modal-content">
        {" "}
        <h2>Loading</h2>
        <ReactLoading
          type={"bubbles"}
          color={"#9B27AF"}
          height={"50%"}
          width={"50%"}
        />
      </div>
    );
    setLoadingModal(true);
    const substracted = await discountPassWithEmail(
      locationNumber,
      pass.email,
      pass.quantity
    );

    if(substracted.result) {
      setAlert({
        state: false,
        message: "",
        type: "alert alert__warning"
      });
      const user = await getUserPersonalDataByEmail(locationNumber, pass.email);
      setpass({ ...pass, passes: user.passes });
      setpass({ ...pass, passes: user.passes });
      setLoadingModal(false);
      setSuccessModal(true);
      setModalContent(
        <>
          {" "}
          <AiFillCheckCircle size="5rem" color="#198754" />
          <h1>Success!</h1>
        </>
      );
    } else {
      setLoadingModal(false);
      setAlert({
        state: true,
        message: substracted.error,
        type: "alert alert__warning"
      });
    }
  };

  const create = async () => {
    if(user.email === "" || user.street === "" || user.house === "" || user.unit === "") {
      setAlert({
        state: true,
        message: "Please check fields",
        type: "alert alert__warning"
      });
      return 
    }
    disableButton("create")
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setModalContent(
      <div className="main-modal-content">
        {" "}
        <h2>Loading</h2>
        <ReactLoading
          type={"bubbles"}
          color={"#9B27AF"}
          height={"50%"}
          width={"50%"}
        />
      </div>
    );
    setLoadingModal(true);
    const hashEmail = hex_sha1(user.email);
    const code = makeid(6);
    const created = await createUser(
      locationNumber,
      user.email,
      user.street,
      user.house,
      user.unit,
      hashEmail,
      code,
      settedPasses
    );
    if(created.result) {
      setAlert({
        state: false,
        message: "",
        type: "alert alert__warning"
      });
      setLoadingModal(false);
      setSuccessModal(true);
      setModalContent(
        <>
          {" "}
          <AiFillCheckCircle size="5rem" color="#198754" />
          <h1>Success!</h1>
        </>
      );
    } else {
      setLoadingModal(false);
      setAlert({
        state: true,
        message: created.error,
        type: "alert alert__warning"
      });
    }
  };

  const uploadxls = async () => {
    disableButton("excel")
    if(excelData.excelData) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setModalContent(
        <div className="main-modal-content">
          {" "}
          <h2>Loading</h2>
          <ReactLoading
            type={"bubbles"}
            color={"#9B27AF"}
            height={"50%"}
            width={"50%"}
          />
        </div>
      );
      setLoadingModal(true);
      const data = await JSONtoXLS(locationNumber, settedPasses);
      console.log(data);
      setLoadingModal(false);
      setSuccessModal(true);
      setModalContent(
        <>
          {" "}
          <AiFillCheckCircle size="5rem" color="#198754" />
          <h1>Success!</h1>
        </>
      );
      setAlert({
        state: true,
        message: "Some excel can last some minutes, please be pacient",
        type: "alert alert__success"
      });
    } else {
      setAlert({
        state: true,
        message: "Please, upload a valid excel file",
        type: "alert alert__warning"
      });
    }
  };

  const eraselUser = async ({email, publicId, privateId, street, house, unit}) => {
    disableButton("delete")
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setModalContent(
      <div className="main-modal-content">
        {" "}
        <h2>Loading</h2>
        <ReactLoading
          type={"bubbles"}
          color={"#9B27AF"}
          height={"50%"}
          width={"50%"}
        />
      </div>
    );
    setLoadingModal(true);
    const del = await deleteUser({email:email, publicId:publicId, privateId:privateId, locationNumber:locationNumber, street: street, house: house, unit:unit });
    console.log(del);
    setLoadingModal(false);
    setSuccessModal(true);
    setModalContent(
      <>
        {" "}
        <AiFillCheckCircle size="5rem" color="#198754" />
        <h1>Success!</h1>
      </>
    );
  };

  const disableButton = (type) => {
   setButtonDesactivated({...buttonDesactivated, [type]: true})
    setTimeout(() =>  setButtonDesactivated({...buttonDesactivated, [type]: false}), 5000);
  }


  const AlertComponent = () => {
    return alert.state ? (
      <Alert className={alert.type}>
        <p>{alert.message}</p>
      </Alert>
    ) : null;
  };

  const changePag = (number) => {
    setpaginterval({
      ...paginterval,
      initial: 7 * number - 7,
      final: 7 * number,
    });
    setCurrentPage(number);
  };
  // 🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧

  const next = (number) => {
    setpaginterval({
      ...paginterval,
      initial: 7 * number - 7,
      final: 7 * number,
    });
    setCurrentPage(number);
    if (number < 6) {
      setStepsShrink({ initial: 0, final: 5 });
    } else {
      setStepsShrink({ initial: number - 1, final: number + 4 });
    }
  };

  

  return (
    <div className="user--Widget">
    
        <AlertComponent />
    
      <div className="user--operations--card">
        <h1>User Operations</h1>
        <h2>Add or substract passes</h2>
        <div className="add--substract">
          <label htmlFor="add-sub--input">Email</label>
          <input
            name="email"
            className="add-sub--input--email"
            type="text"
            placeholder={pass.email}
            onChange={passHandleChange}
          />
          <label htmlFor="add-sub--input">Quantity</label>
          <input
            name="quantity"
            className="add-sub--input"
            type="number"
            placeholder={pass.quantity}
            onChange={passHandleChange}
            min="0"
          />
          <button style={buttonDesactivated.addSub? {pointerEvents: "none", background: "#9e9e9e"}: {}} onClick={add}>Add</button>
          <button style={buttonDesactivated.addSub? {pointerEvents: "none", background: "#9e9e9e"}: {}} onClick={substract}>Sub</button>
        </div>

        <h2>Add User</h2>
        <div className="add--user">
          <div className="userWidget--row">
            <label htmlFor="user-input">Email</label>
            <input
              onChange={addHandleChange}
              name="email"
              className="add-sub--input--email"
              type="text"
            />

            <label htmlFor="user-input">Street</label>
            <input
              onChange={addHandleChange}
              name="street"
              className="user-input"
              type="text"
            />

            <label htmlFor="user-input">House </label>
            <input
              onChange={addHandleChange}
              name="house"
              className="user-input"
              type="text"
            />
  
            <div className="unit--input--group">
                    <label htmlFor="user-input">Unit</label>
                    <input
                      onChange={addHandleChange}
                      name="unit"
                      className="user-input"
                      type="text"
                    />
            </div>


            <button className="add--user--button--mobile" onClick={create} style={buttonDesactivated.create? {pointerEvents: "none", background: "#9e9e9e"}: {}}>
              Add User
            </button>
          </div>
          <div className="userWidget--row--center">
            <button className="add--user--button" onClick={create} style={buttonDesactivated.create? {pointerEvents: "none", background: "#9e9e9e"}: {}}>
              Add User
            </button>
          </div>
        </div>

        <h2>Add users by excel</h2>
        <div className="add-excel--users">
          {/*here*/}
          

          <label className="excel--label" htmlFor="fileUpload">
          {excelData.excelData ? "Done!" : "Upload"}
          <input
            name="excelData"
            onChange={excelHandleChange}
            className="input--file"
            type="file"
            id="fileUpload"
            accept=".xls,.xlsx"
          />
        </label>  
        <a className="download--sample--button" href="/ExampleBook.xlsx" download="ExampleBook.xlsx">Template</a>
          <input type="button" id="upload" value="Process" onClick={uploadxls} style={buttonDesactivated.excel? {pointerEvents: "none", background: "#9e9e9e"}: {}} />
        </div>
      </div>

      <div className="p3-container-2">
        <h1>Manage Users</h1>
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th className="hide--in--mobile">Street</th>
              <th className="hide--in--mobile">House</th>
              <th className="hide--in--mobile">Unit</th>
              <th className="hide--in--mobile">Passes</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {userData.slice(paginterval.initial, paginterval.final).map((value) => {
              return (
                <tr key={value.hashEmail}>
                  <td>{value.email}</td>
                  <td className="hide--in--mobile">{value.street}</td>
                  <td className="hide--in--mobile">{value.house}</td>
                  <td className="hide--in--mobile">{value.unit}</td>
                  <td className="hide--in--mobile">{value.passes}</td>
                  <td>
                    <button
                      onClick={() =>
                        eraselUser({email:value.email, publicId:value.publicId, privateId:value.privateId, street: value.street, house: value.house, unit: value.unit})
                      }
                      style={buttonDesactivated.delete? {pointerEvents: "none"}: {}}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
        <div className="pagination">
          <button
            onClick={() => next(currentPage - 1)}
            className="pagination-navigation"
            style={
              currentPage === 1
                ? { pointerEvents: "none" }
                : { cursor: "pointer" }
            }
          >
            <FaArrowLeft
              size="1rem"
              color={currentPage === 1 ? "#bebebe" : "#6A0080"}
            />
          </button>
          {steps.slice(stepsShrink.initial, stepsShrink.final).map((step) => {
            return (
              <button
                key={step.value}
                className="pagination-numbers"
                style={
                  currentPage === step.value
                    ? { backgroundColor: "#6A0080", color: "#fff" }
                    : null
                }
                onClick={() => changePag(step.value)}
              >
                {" "}
                {step.value}
              </button>
            );
          })}

          <button
            onClick={() => next(currentPage + 1)}
            className="pagination-navigation"
            style={
              currentPage >= paginterval.pages
                ? { pointerEvents: "none" }
                : { cursor: "pointer" }
            }
          >
            <FaArrowRight
              size="1rem"
              color={currentPage >= paginterval.pages ? "#bebebe" : "#6A0080"}
            />
          </button>
        </div>
      <GenericModal state={excelModal} setState={setExcelModal}>
        {modalContent}
      </GenericModal>
      <GenericModal state={loadingModal} setState={""}>
        {modalContent}
      </GenericModal>
      <GenericModal state={successModal} setState={setSuccessModal}>
        {modalContent}
      </GenericModal>
    </div>
  );
};

export default UserWidget;
