const VehicleSelector = ({personalInfoHandleChange, vehicleMake}) => {

    return (
        <select name="vehicleMake" value={vehicleMake} onChange={personalInfoHandleChange} className="info--input">
            <option value="">-SELECT-</option>
            <option value="ACURA">ACURA</option>
            <option value="ASTON MARTIN">ASTON MARTIN</option>
            <option value="AUDI">AUDI</option>
            <option value="BENTLEY">BENTLEY</option>
            <option value="BMW">BMW</option>
            <option value="BUICK">BUICK</option>
            <option value="CADILLAC">CADILLAC</option>
            <option value="CHEVROLET">CHEVROLET</option>
            <option value="CHRYSLER">CHRYSLER</option>
            <option value="DODGE">DODGE</option>
            <option value="FERRARI">FERRARI</option>
            <option value="FORD">FORD</option>
            <option value="GMC">GMC</option>
            <option value="HONDA">HONDA</option>
            <option value="HUMMER">HUMMER</option>
            <option value="HYUNDAI">HYUNDAI</option>
            <option value="INFINITI">INFINITI</option>
            <option value="ISUZU">ISUZU</option>
            <option value="JAGUAR">JAGUAR</option>
            <option value="JEEP">JEEP</option>
            <option value="KIA">KIA</option>
            <option value="LAMBORGHINI">LAMBORGHINI</option>
            <option value="LAND ROVER">LAND ROVER</option>
            <option value="LEXUS">LEXUS</option>
            <option value="LINCOLN">LINCOLN</option>
            <option value="LOTUS">LOTUS</option>
            <option value="MASERATI">MASERATI</option>
            <option value="MAYBACH">MAYBACH</option>
            <option value="MAZDA">MAZDA</option>
            <option value="MERCEDES-BENZ">MERCEDES-BENZ</option>
            <option value="MERCURY">MERCURY</option>
            <option value="MINI">MINI</option>
            <option value="MITSUBISHI">MITSUBISHI</option>
            <option value="NISSAN">NISSAN</option>
            <option value="PONTIAC">PONTIAC</option>
            <option value="PORSCHE">PORSCHE</option>
            <option value="ROLLS-ROYCE">ROLLS-ROYCE</option>
            <option value="SAAB">SAAB</option>
            <option value="SATURN">SATURN</option>
            <option value="SUBARU">SUBARU</option>
            <option value="SUZUKI">SUZUKI</option>
            <option value="TOYOTA">TOYOTA</option>
            <option value="VOLKSWAGEN">VOLKSWAGEN</option>
            <option value="VOLVO">VOLVO</option>
        </select>
    )
}

export default VehicleSelector