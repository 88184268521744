import { useState, useEffect } from "react";

// under construction 🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧

import {getUserPersonalDataByEmail,discountPassWithEmail, addPassWithEmail} from '../Firebase/Firebase'

// 🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧

import Alert from "../UIComponents/Alert";
import { AiFillCheckCircle } from "react-icons/ai/";
import ReactLoading from 'react-loading';


const TransferPasses = ({
  passes,
  locationNumber,
  currentUser,
  setModalContent,
}) => {
  const [user, setUser] = useState({
    userToTransfer: "",
    passesToTransfer: "",
  });
  const [alert, setAlert] = useState({ state: false, message: " ", type: "" });
  const [isActive, setIsActive] = useState(false);
  const handleChange = ({ target }) => {
    setUser((state) => ({
      ...state,
      [target.name]:
        target.type === "number" && target.value > passes
          ? passes
          : target.value.replace(/\s/g, ""),
    }));
  };

  useEffect((currentUser) => {
    if (
      user.userToTransfer.length === 0 ||
      user.passesToTransfer.length === 0 ||
      passes <= 0 ||
      user.userToTransfer === currentUser ||
      user.userToTransfer.indexOf("@") === -1
    ) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [user]);

  //manejo de error
  const transfer = async () => {
    if (passes > 0 && user.userToTransfer !== currentUser) {
      const userPersonalinfo = await getUserPersonalDataByEmail(locationNumber,user.userToTransfer)
      if (!userPersonalinfo) {

        setAlert({ state: true, message: "Not user Found", type: "warning" });
        return
      }
      else {
        await setModalContent(<div className="main-modal-content"> <h2>Loading</h2><ReactLoading type={"bubbles"} color={"#9B27AF"} height={"50%"} width={"50%"} /></div>)
          const add = await addPassWithEmail(
            locationNumber,
            user.userToTransfer,
            user.passesToTransfer
          );
          if (add) {
            const discounted = await discountPassWithEmail(
              locationNumber,
              currentUser,
              user.passesToTransfer
            );
            discounted
              ? setModalContent(<> <AiFillCheckCircle size="3rem" color="#198754" />
              <h1>Success!</h1></>)
              : setModalContent("Connection error");
          } 
  
      }
    }
  };
  const AlertComponent = () => {
    return alert.state ? (
      <Alert
        className={
          alert.type === "success"
            ? "alert alert__success"
            : "alert alert__warning"
        }
        style = {{borderRadius: "10px", marginBottom: ".5rem"}}
      >
        <p>{alert.message}</p>
      </Alert>
    ) : null;
  };

  return (
    <div>
      <AlertComponent />
      <h2>You have {passes} passes</h2>
      <p>Transfer to (email)</p>
      <input
        onChange={handleChange}
        type="email"
        name="userToTransfer"
        value={user.userToTransfer}
      />
      <p>Amount</p>
      <input
        onChange={handleChange}
        type="number"
        name="passesToTransfer"
        value={user.passesToTransfer}
        min="0"
        max={passes}
      />
      <button
        className={
          isActive ? "login--btn" : "login--btn login--btn__desactivated"
        }
        onClick={transfer}
      >
        Send
      </button>

      {/* <ReactLoading type={"bubbles"} color={"#9B27AF"} height={"30%"} width={"30%"} /> */}
      
    </div>
  );
};

export default TransferPasses;
