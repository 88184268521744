import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./components/styles/css/Main.css";
import Login from "./components/Views/Login";
import FormSplitter from "./components/Views/FormSplitter";
import Dashboard from "./components/Views/Dashboard";
import GeneralForm from "./components/Views/ParkForms/GeneralForm";
import GeneralFormWithCode from "./components/Views/ParkForms/GeneralFormWithCode";
import AdminParent from './components/Views/admin/AdminParent'
import AdminLogin from "./components/Views/admin/AdminLogin";
import SignUp from './components/Views/SignUp'

ReactDOM.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FormSplitter/>}/>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />

        <Route path="id" element={<GeneralForm />}>
        <Route
            path=":locationNumber"
            element={<GeneralForm />}
          />
        </Route>

        <Route path="code" element={<GeneralFormWithCode/>}>
          <Route path=":locationNumber" element={<GeneralFormWithCode/>} />
          <Route
            path=":locationNumber/:street"
            element={<GeneralFormWithCode />}
          />
          <Route
            path=":locationNumber/:street/:house"
            element={<GeneralFormWithCode />}
          />
          <Route
            path=":locationNumber/:street/:house/:unit"
            element={<GeneralFormWithCode/>}
          />
          </Route>

          <Route path='admin' element={<AdminParent/>}>
          <Route path=":locationNumber" element={<AdminParent />} />
          </Route>

          <Route path='adminlogin' element={<AdminLogin/>}/>

        
          <Route path="dashboard" element={<Dashboard />}>
            <Route path=":locationNumber" element={<Dashboard />} />
          </Route>
    
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
      </Routes>
    </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();