import { HiMenu } from "react-icons/hi/";
import { CgClose } from "react-icons/cg/";
import { AiFillCaretDown } from "react-icons/ai/";
import { AiFillCaretUp } from "react-icons/ai/";
import { useState } from "react";
import { motion } from "framer-motion";

const NavLinks = (props) => {
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };
  return (
    <>
      <ul className="navList">
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="#" onClick={() => props.setState(1)} className = {props.state === 1 ? "selected": ""}>
            Location
          </a>
        </motion.li>

        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="#" onClick={() => props.setState(3)} className = {props.state === 3 ? "selected": ""}>
            Parking
          </a>
        </motion.li>

        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="#" onClick={() => props.setState(2)} className = {props.state === 2 ? "selected": ""}>
            Users
          </a>
        </motion.li>

        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="#" onClick={() => props.Out()}>
            Exit
          </a>
        </motion.li>
      </ul>
    </>
  );
};

const DropNavLinks = (props) => {
  const [enabled, setEnabled] = useState(false);

  const toggle = (enabled) => {
    if (!enabled) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  };

  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };
  return (
    <>
      <ul className="navList">
        <div className="dropdown">
          <div onClick={() => toggle(enabled)} className="drop--inline">
            <button
              className={!enabled ? "dropbtn" : "dropbtn dropbtn__active"}
            >
              {props.email}
            </button>
            <AiFillCaretDown
              className={!enabled ? "drop" : "drop drop__hidden"}
              size="1em"
              color="white"
            />
            <AiFillCaretUp
              className={enabled ? "dropUp" : "dropUp dropUp__hidden"}
              size="1em"
              color="white"
            />
          </div>
          <div
            className={
              enabled
                ? `dropdown-content dropdown-content__active`
                : "dropdown-content dropdown-content__inactive"
            }
          >
            <motion.li
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.1 }}
              onClick={() => props.isMobile && props.closeMobileMenu()}
            >
              <a href="#" onClick={() => props.Out()}>
                Exit
              </a>
            </motion.li>
          </div>
        </div>
      </ul>
    </>
  );
};

const Navigation = ({ email, Out, setState, state }) => {
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };
  return (
    <nav className="navBar">
      {/*  <img className ="logo" src="/Fomofood.png" alt="" /> */}
      <div className="nav--container-logo">
        <h2>Logo</h2>
      </div>

      {/* here are the navigation buttons */}
      <div className="admin--switch">
        <ul className="navList">
          <motion.li
            initial={animateFrom}
            animate={animateTo}
            transition={{ delay: 0.05 }}
          >
            <a href="#" onClick={() => setState(1)} className = {state === 1 ? "selected": ""}>
              Location
            </a>
          </motion.li>
          <motion.li
            initial={animateFrom}
            animate={animateTo}
            transition={{ delay: 0.05 }}
          >
            <a href="#" onClick={() => setState(3)} className = {state === 3 ? "selected": ""} >
              Parking
            </a>
          </motion.li>
          <motion.li
            initial={animateFrom}
            animate={animateTo}
            transition={{ delay: 0.05 }}
          >
            <a href="#" onClick={() => setState(2)} className = {state === 2 ? "selected": ""}>
              Users
            </a>
          </motion.li>
        </ul>
      </div>

      <div className="nav--container-menu">
        <DropNavLinks email={email} Out={Out} />
      </div>
    </nav>
  );
};

const NavigationMobile = ({ Out, setState, state }) => {
  const [toggle, setToggle] = useState(false);

  const hamburguerIcon = (
    <HiMenu
      className="hamburguer"
      size="2.5em"
      color="white"
      onClick={() => setToggle(!toggle)}
    />
  );
  const closeIcon = (
    <CgClose
      className="hamburguer"
      size="2em"
      color="white"
      onClick={() => setToggle(!toggle)}
    />
  );

  const closeMobileMenu = () => setToggle(false);

  return (
    <nav className="navBarMobile">
      {/*  <img className ="mlogo" src="/Fomofood.png" alt="" /> */}
      <div className="nav--container-logo">
        <h2>Logo</h2>{" "}
      </div>
      <div className="nav--container-menu">
        {toggle ? closeIcon : hamburguerIcon}
        {toggle && (
          <NavLinks
            Out={Out}
            isMobile={true}
            closeMobileMenu={closeMobileMenu}
            setState={setState}
            state = {state}
          />
        )}
      </div>
    </nav>
  );
};

const AdminNavBar = ({ email, Out, setState, state }) => {
  return (
    <>
      <Navigation email={email} Out={Out} setState={setState} state = {state} />
      <NavigationMobile Out={Out} setState={setState} state = {state} />
    </>
  );
};

export default AdminNavBar;
