import Admin from "./Admin";
import AdminLogin from "./AdminLogin";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getAuth } from "@firebase/auth";
import db, { app } from "../../config";
import {getSettedPasses} from '../../Firebase/Firebase'
import {
  getDocs,
  query,
  where,
  collection
} from "@firebase/firestore";

const AdminParent = () => {
  const params = useParams()
  const auth = getAuth(app);
  const [state, setState] = useState(null)
  const [settedPasses, setsettedPasses] = useState(null)
  const [email, setEmail] = useState("")
  const usersRef = collection(db,`Locations/${params.locationNumber}/userPersonalInfo`)

  useEffect(async () => {
    auth.onAuthStateChanged(async (user) => {
    try {    
        const isAunthenticated =user.uid
        const q = query(usersRef, where('email', '==', user.email), where("role", "==", "admin"))
        const isUser = await getDocs(q)
        if (!isUser.empty) {
          setState(true);
          getPasses()
          setEmail(user.email)
        } else{
          setState(false);
        }
    } catch (error) {
          setState(false);
    }
    });
  }, []);

  const getPasses = async () => {
    const passes = await getSettedPasses(params.locationNumber)
    setsettedPasses(passes)
  }


  if (state) { 
    return <Admin email = {email} locationNumber = {params.locationNumber} settedPasses = {settedPasses} />;
  } else if (state === false) {
    return <AdminLogin />;
  } else {
    return <></>
}
};

export default AdminParent;
