import ModalBase from "../../../UIComponents/ModalBase"

const ReportModal = ({state, setState, reportUser}) => {
  const imgHandleChange = async ({target}) => {
    setState({...state ,files: target.files[0]})
  }
 
  const ModalComponent = () => {
    return (
        <div id="modal1" className="main-modal">
          <div className="main-modal-content">
          <div className="report--modal--content">
    <h1 id="report--modal--title">Report a car</h1>
    <p id="report--modal--text"><strong>License Plate:</strong> {state.plate}</p>
    
    <label className="img--label" htmlFor="inputImg">
    {state.files === null? "Upload image" : "Done!"}
    <input id = 'inputImg' className="inputImg" type= "file" accept=".jpg" name="carimg" onChange={imgHandleChange} />
    </label>  
   <div className="spacer"></div>
    {/* tengo que crear el css de desactivated */}
    <button className="Button" style= {state.files === null? {backgroundColor: '#9e9e9e',pointerEvents: 'none'} : {} } onClick={() =>reportUser({locationNumber:state.locationNumber, id:state.id, plate:state.plate, dueDate:state.dueDate, street:state.street, house:state.house })}>Report</button>
    </div>   
        </div>
        </div>
    )
  }
  return (
    <>
        {state.cond ?  <div className="main-modal-component"><ModalBase close={()=>setState({...state, cond:false})} /><ModalComponent></ModalComponent> </div>  : null}
    </>
  );
};

export default ReportModal

