import db, { app } from "../config";
import PlatePad from "../Views/ParkForms/AuxFunctions/PlatePad";
import { hex_sha1 } from "../Views/ParkForms/AuxFunctions/Sha1";
import {
  getAuth,
  createUserWithEmailAndPassword,
} from "@firebase/auth";
import {
  getDocs,
  getDoc,
  query,
  where,
  collection,
  doc,
} from "@firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions(app);
//function for make code in xlsJson

const makeid = (length) => {
  var result           = '';
  var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
 }
 return result;
}

//* Local Functions
const checkLocationNumber = async (LocationNumber) => {
  const docRef = doc(db, "Locations", LocationNumber);
  const docSnap = await getDoc(docRef);
  let data;
  if (docSnap.exists()) {
    data = docSnap.id;
  } else {
    data = null;
  }
  return data;
};

const getCapacity = async (LocationNumber) => {
  const docRef = doc(db, "Locations", LocationNumber);
  const docSnap = await getDoc(docRef);
  let data;
  if (docSnap.exists()) {
    data = docSnap.data().capacity;
  } else {
    data = null;
  }
  return data;
};

const getSettedPasses = async (LocationNumber) => {
  const docRef = doc(db, "Locations", LocationNumber);
  const docSnap = await getDoc(docRef);
  let data;
  if (docSnap.exists()) {
    data = docSnap.data().numberOfPasses;
  } else {
    data = null;
  }
  return data;
};

const getStreets = async (locationNumber) => {
  const UsersRef = collection(db, `Locations/${locationNumber}/streets`);
  const querySnapshot = await getDocs(UsersRef);
  let data = [];
  if (querySnapshot.docs.length === 0) {
    return (data = null);
  } else {
    querySnapshot.forEach((document) =>
      data.push({ street: document.data().street, id: document.id })
    );
  }
  return data;
};

const getHouses = async (locationNumber, street) => {
  const UsersRef = collection(db, `Locations/${locationNumber}/houses`);
  const q = query(UsersRef, where("street", "==", street));
  const querySnapshot = await getDocs(q);
  let data = [];
  if (querySnapshot.docs.length === 0) {
    return null;
  } else {
    querySnapshot.forEach((document) =>
      data.push({ house: document.data().house, id: document.id })
    );
    return data;
  }
};

const getUnits = async (locationNumber, street, house) => {
  const UsersRef = collection(db, `Locations/${locationNumber}/units`);
  const q = query(
    UsersRef,
    where("street", "==", street),
    where("house", "==", house)
  );
  const querySnapshot = await getDocs(q);
  let data = [];
  if (querySnapshot.docs.length === 0) {
    return null;
  } else {
    querySnapshot.forEach((document) =>
      data.push({ unit: document.data().unit, id: document.id })
    );
    return data;
  }
};

const getRenovationTime = async (locationNumber) => {
  const parkingRef = doc(db, `Locations/${locationNumber}`);  
  const snapshot = await getDoc(parkingRef)
  return snapshot.data().passesRenovation
};

const getForgiveChances= async (locationNumber) => {
  const parkingRef = doc(db, `Locations/${locationNumber}`);  
  const snapshot = await getDoc(parkingRef)
  return snapshot.data().forgiveChances
};

const getDueTimeHours = async (locationNumber) => {
  const parkingRef = doc(db, `Locations/${locationNumber}`);  
  const snapshot = await getDoc(parkingRef)
  return snapshot.data().dueTime
};

const updateDueTimeHours = async (locationNumber, value) => {
  const dis = httpsCallable(functions, "updateDueTimeHours");
  const fn = await dis({ locationNumber: locationNumber, value: value });
  try {
    return fn.data;
  } catch (error) {
    console.log(error);
  }
}

const updateSettedPasses = async (locationNumber, value) => {
  const dis = httpsCallable(functions, "updateSettedPasses");
  const fn = await dis({ locationNumber: locationNumber, value: value });
  try {
    return fn.data;
  } catch (error) {
    console.log(error);
  }
}

const updateRenovationTime = async (locationNumber, value) => {
  const dis = httpsCallable(functions, "updateRenovationTime");
  const fn = await dis({ locationNumber: locationNumber, value: value });
  try {
    return fn.data;
  } catch (error) {
    console.log(error);
  }
}

const getforgiveChancesRenovationTime = async (locationNumber, value) => {
  const parkingRef = doc(db, `Locations/${locationNumber}`);  
  const snapshot = await getDoc(parkingRef)
  return snapshot.data().forgiveChancesRenovationTime
}

const updateParkingCapacity = async (locationNumber, value) => {
  const dis = httpsCallable(functions, "updateParkingCapacity");
  const fn = await dis({ locationNumber: locationNumber, value: value });
  try {
    return fn.data;
  } catch (error) {
    console.log(error);
  }
}

const updateForgiveChances = async (locationNumber, value) => {
  const dis = httpsCallable(functions, "updateForgiveChances");
  const fn = await dis({ locationNumber: locationNumber, value: value });
  try {
    return fn.data;
  } catch (error) {
    console.log(error);
  }
}

const updateForgiveChancesRenovationTime = async (locationNumber, value) => {
  const dis = httpsCallable(functions, "updateForgiveChancesRenovationTime");
  const fn = await dis({ locationNumber: locationNumber, value: value });
  try {
    return fn.data;
  } catch (error) {
    console.log(error);
  }
}

//* Cloud Functions

//* getters
const getOwnerUserByCode = async (locationNumber, code) => {
  const dis = httpsCallable(functions, "getOwnerUserByCode");
  const fn = await dis({ locationNumber: locationNumber, code: code });
  try {
    return fn.data;
  } catch (error) {
    console.log(error);
  }
};

const getOwnerUser = async (locationNumber, street, house, unit) => {
  const dis = httpsCallable(functions, "getOwnerUser");
  try {
    const fn = await dis({
      locationNumber: locationNumber,
      street: street,
      house: house,
      unit: unit,
    });
    return fn.data;
  } catch (error) {
    console.log(error);
  }
};

const getUserPersonalDataByEmail = async (locationNumber, email) => {
  const dis = httpsCallable(functions, "getUserPersonalDataByEmail");
  try {
    const fn = await dis({ locationNumber: locationNumber, email: email });
    return fn.data;
  } catch (error) {
    console.log(error);
  }
};

//* passes

const discountPass = async (locationNumber, hashEmail, value) => {
  const dis = httpsCallable(functions, "discountPass");
  try {
    const fn = await dis({
      locationNumber: locationNumber,
      hashEmail: hashEmail,
      value: value,
    });
    return fn.data;
  } catch (error) {
    console.log(error);
  }
};

const addPassWithEmail = async (locationNumber, email, value) => {
  try {
    const dis = httpsCallable(functions, "addPassWithEmail");
    const fn = await dis({
      locationNumber: locationNumber,
      email: email,
      value: value,
    });
    return fn.data;
  } catch (error) {
    console.log(error);
  }
};

const discountPassWithEmail = async (locationNumber, email, value) => {
  const dis = httpsCallable(functions, "discountPassWithEmail");
  try {
    const fn = await dis({
      locationNumber: locationNumber,
      email: email,
      value: value,
    });
    return fn.data;
  } catch (error) {
    console.log(error);
  }
};

//* Misc

const discountCapacity = (locationNumber) => {
  const dis = httpsCallable(functions, "discountCapacity");
  dis({ locationNumber: locationNumber })
    .then((result) => {
      console.log(result.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

const sendEmail = (locationNumber, plate, hashEmail, id, emailParking) => {
  const dis = httpsCallable(functions, "sendEmail");
  dis({
    locationNumber: locationNumber,
    plate: plate,
    hashEmail: hashEmail,
    id: id,
    emailParking: emailParking,
  })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const addParking = async ({
  locationNumber,
  plate,
  email,
  street,
  house,
  unit,
  phone,
  vehicleMake,
  vehicleColor,
  vehicleYear,
  addParking
}
) => {
  const hashPlate = hex_sha1(plate);
  const hiddenPlate = PlatePad(plate);
  const dis = httpsCallable(functions, "addParking");
  try {
    const fn = await dis({
      locationNumber: locationNumber,
      plate: plate,
      hiddenPlate: hiddenPlate,
      hashPlate: hashPlate,
      street: street,
      house: house,
      unit: unit,
      email: email,
      phone: phone,
      vehicleMake: vehicleMake,
      vehicleColor: vehicleColor,
      vehicleYear: vehicleYear,
      addParking: addParking,
    });
    return fn.data;
  } catch (error) {
    console.log(error);
  }
};

const auth = async () => {
  const dis = httpsCallable(functions, 'auth');
  try {
    const fn = await dis({});
    return fn.data;
  } catch (error) {
    console.log(error);
  }
};

const createUser = async (locationNumber, email, street, house, unit,hashEmail, code, passes) => {
  const dis = httpsCallable(functions, 'createUser');
  try {
    const fn = await dis({locationNumber:locationNumber,email:email, street:street, house:house, unit:unit, hashEmail: hashEmail, code: code, passes: passes});
    return fn.data;
  } catch (error) {
    console.log(error);
  }
};

const createUserLite = async ({email, password, phone}) => {
  try {
    const req = await createUserWithEmailAndPassword(getAuth(app), email, password)
    return true
  } catch (err) {
    return false
  }
};

const xlsJson = async (json,locationNumber, settedPasses) => {
  const usersArray = json.map(user => {
    return {...user,hashEmail: hex_sha1(user.email), code: makeid(9)}
  })
  const dis = httpsCallable(functions, 'addUserByExcel');
  try {
    const fn = await dis({json:json, usersArray:usersArray, locationNumber:locationNumber, passes: settedPasses});
    return fn.data;
  } catch (error) {
    console.log(error);
  } 
};

const getAllUsers = async (locationNumber) => {
  const users = []
  const emails = []
  const querySnapshot = await getDocs(collection(db, `Locations/${locationNumber}/users`));
  querySnapshot.forEach((doc) => {
    users.push({publicId:doc.id,street:doc.data().street, house: doc.data().house, unit: doc.data().unit, hashEmail: doc.data().hashEmail })
});
  const emailSnapshot = await getDocs(collection(db, `Locations/${locationNumber}/userPersonalInfo`));
  emailSnapshot.forEach((doc) => {
    emails.push({privateId:doc.id,email:doc.data().email, hashEmail: doc.data().hashEmail, passes: doc.data().passes})
});

const dataArray = users.map(user => {
  let res = emails.find(data => data.hashEmail === user.hashEmail);
  if(res) {
    return {...user, email: res.email, privateId: res.privateId, passes: res.passes }
  }
})
return dataArray
}

const deleteUser = async ({email, publicId, privateId, locationNumber, street, house, unit}) => {
  const dis = httpsCallable(functions, 'deleteUser');
  try {
    const fn = await dis({email:email, publicId:publicId, privateId:privateId, locationNumber:locationNumber, street: street, house: house, unit: unit});
    return fn.data;
  } catch (error) {
    console.log(error);
  }
}

const report = async ({locationNumber, id, plate, dueDate, street, house,downloadURL}) => {
  const dis = httpsCallable(functions, 'report');
  try {
    const fn = await dis({locationNumber:locationNumber, id:id, plate: plate, dueDate:dueDate, street:street, house:house, downloadURL:downloadURL});
    return fn.data;
  } catch (error) {
    console.log(error);
  }
}

const reportWithoutRegister = async ({locationNumber, plate, street, house,downloadURL}) => {
  const dis = httpsCallable(functions, 'reportWithoutRegister');
  try {
    const fn = await dis({locationNumber:locationNumber, plate: plate, street:street, house:house, downloadURL: downloadURL});
    return fn.data;
  } catch (error) {
    console.log(error);
  }
}


export {
  createUser,
  checkLocationNumber,
  getCapacity,
  getStreets,
  getHouses,
  getUnits,
  getOwnerUserByCode,
  getOwnerUser,
  getUserPersonalDataByEmail,
  discountPass,
  addPassWithEmail,
  discountPassWithEmail,
  discountCapacity,
  sendEmail,
  addParking,
  auth,
  xlsJson,
  getSettedPasses,
  getAllUsers,
  deleteUser,
  getRenovationTime,
  updateSettedPasses,
  updateRenovationTime,
  updateParkingCapacity,
  updateDueTimeHours,
  getDueTimeHours,
  report,
  reportWithoutRegister,
  updateForgiveChances,
  getForgiveChances,
  updateForgiveChancesRenovationTime,
  getforgiveChancesRenovationTime,
  createUserLite
};
