import { HiMenu } from "react-icons/hi/";
import { CgClose } from "react-icons/cg/";
import { useState } from "react";
import { motion } from "framer-motion";

const NavLinks = (props) => {
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };
  return (
    <>
      <ul className="absolute--navList">
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.05 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="/#">about</a>
        </motion.li>
        <motion.li
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.1 }}
          onClick={() => props.isMobile && props.closeMobileMenu()}
        >
          <a href="/#">terms</a>
        </motion.li>
      </ul>
    </>
  );
};

const Navigation = ({locationID}) => {
  return (
    <nav className="absolute--navBar">
      {/*  <img className ="logo" src="/Fomofood.png" alt="" /> */}
      <h2>Logo</h2>
      {locationID ? <b style={{color:"#fff"}}>Location: {locationID.toLowerCase()}</b> : null }
      
      <NavLinks />
    </nav>
  );
};

const NavigationMobile = ({locationID}) => {
  const [toggle, setToggle] = useState(false);

  const hamburguerIcon = (
    <HiMenu
      className="hamburguer"
      size="2.5em"
      color="white"
      onClick={() => setToggle(!toggle)}
    />
  );
  const closeIcon = (
    <CgClose
      className="hamburguer"
      size="2em"
      color="white"
      onClick={() => setToggle(!toggle)}
    />
  );

  const closeMobileMenu = () => setToggle(false);

  return (
    <nav className="absolute--navBarMobile">
      {/*  <img className ="mlogo" src="/Fomofood.png" alt="" /> */}
      <h2>Logo</h2>
      {locationID ? <b style={{color:"#fff"}}>Location: {locationID.toLowerCase()}</b> : null }
      {toggle ? closeIcon : hamburguerIcon}
      {toggle && <NavLinks isMobile={true} closeMobileMenu={closeMobileMenu} />}
    </nav>
  );
};

const PublicNavBar = ({locationID}) => {
  return (
    <>
      <Navigation locationID={locationID}/>
      <NavigationMobile  locationID={locationID}/>
    </>
  );
};

export default PublicNavBar;
