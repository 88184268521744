import {
  getStreets,
  getHouses,
  checkLocationNumber,
  getUnits,
  addParking,
} from "../../Firebase/Firebase";
import { useEffect, useState } from "react";
import LocationForm from "./LocationForm";
import StreetForm from "./StreetForm";
import HouseForm from "./HouseForm";
import UnitForm from "./UnitForm";
import PersonalInfo from "./PersonalInfo";
import PublicNavBar from "../../UIComponents/NavBars/PublicNavBar";
//@ramonak/react-progress-bar
import ProgressBar from "@ramonak/react-progress-bar";
import Alert from "../../UIComponents/Alert";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa/";
import { AiFillCheckCircle } from "react-icons/ai/";
import ReactLoading from 'react-loading';
import GenericModal from "../../UIComponents/GenericModal";
import { useParams } from "react-router-dom";
import DataForm from './DataForm'
const GeneralForm = () => {
  const params= useParams()
  const [state, setState] = useState({
    locationNumber: !params.locationNumber? "no code": params.locationNumber ,
    street: "",
    house: "",
    unit: "",
    plate: "",
    email: "",
    phone: "",
    vehicleColor: "#ff0000",
    vehicleYear: "",
    vehicleMake: "",
  });
  //states
  const [step, setStep] = useState(1);
  const [streets, setStreets] = useState([]);
  const [houses, setHouses] = useState([]);
  const [units, setUnits] = useState([]);
  const [policiesCheck, setPoliciesCheck] = useState({ check: false });
  const [submitButton, setSubmitButton] = useState(false);
  const [alert, setAlert] = useState({ state: false, message: " " });
  const [modalContent, setModalContent] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  
  useEffect(async ()=> {
    const st = await getStreets(state.locationNumber);
    if (st) {
      setStreets(st);
    }
  },[])
  
//HandleChanges
  const locationNumberHandleChange = ({ target }) => {
    setState((state) => ({
      ...state,
      [target.name]: target.value.replace(/\s/g, ""),
    }));
  };
  const streetHandleChange = (value) => {
    setState((state) => ({
      ...state,
      street: value.replace(/\s/g, ""),
    }));
  };
  const houseHandleChange = (value) => {
    setState((state) => ({
      ...state,
      house: value.replace(/\s/g, ""),
    }));
    console.log(houses);
  };
  const unitHandleChange = (value) => {
    setState((state) => ({
      ...state,
      unit: value.replace(/\s/g, ""),
    }));
  };
  const personalInfoHandleChange = async ({ target }) => {
    setState((state) => ({
      ...state,
      [target.name]: target.value.replace(/\s/g, ""),
    }));
    console.log(state);
  };
  const policiesCheckHandleChange = async ({ target }) => {
    setPoliciesCheck(() => ({
      [target.name]: target.checked,
    }));
    await activateButtonSubmit();
  };

//Handlers
  const streetHandler = async () => {
    const st = await getStreets(state.locationNumber);
    if (st) {
      setStreets(st);
    }
  };
  const houseHandler = async () => {
    const ho = await getHouses(state.locationNumber, state.street);
    if (ho) {
      setHouses(ho);
    }
  };
  const unitHandler = async () => {
    const un = await getUnits(state.locationNumber, state.street, state.house);
    if (un) {
      setUnits(un);
    }
  };

  //Aux and navigation functions
  const activateButtonSubmit = async () => {
    if (!policiesCheck.check) {
      setSubmitButton(true);
      return true;
    } else {
      setSubmitButton(false);
      return false;
    }
  };

  const nextStep = () => {
    if (step <= 5) {
      setStep(step + 1);
      setAlert({ state: false, message: "" });
    } else {
      return null;
    }
  };
  const previousStep = () => {
    if (step >= 1) {
      setStep(step - 1);
      setAlert({ state: false, message: "" });
    } else {
      return null;
    }
  };

  const Navigation = ({ progress, type }) => {
    if (type === "locationNumber") {
      return (
        <div className="navigation">
          <button
            className="navigation--button"
            style={{ pointerEvents: "none" }}
          >
            <FaArrowLeft size="2em" color="#bebebe" />
          </button>
          <ProgressBar
            bgColor="#9B27AF"
            className="progress"
            completed={progress}
          />
          <button
            className="navigation--button"
            style={state.street === "" ? { pointerEvents: "none" } : {}}
            onClick={() => nextStep()}
          >
            <FaArrowRight
              size="2em"
              color={state.street === "" ? "#bebebe" : "#9B27AF"}
            />
          </button>
        </div>
      );
    }

    if (type === "street") {
      return (
        <div className="navigation">
          <button className="navigation--button" onClick={() => previousStep()}>
            <FaArrowLeft size="2em" color="#9B27AF" />
          </button>
          <ProgressBar
            bgColor="#9B27AF"
            className="progress"
            completed={progress}
          />
          <button
            className="navigation--button"
            onClick={() => nextStep()}
            style={state.house === "" ? { pointerEvents: "none" } : {}}
          >
            {" "}
            <FaArrowRight
              size="2em"
              color={state.house === "" ? "#bebebe" : "#9B27AF"}
            />
          </button>
        </div>
      );
    }

    if (type === "house") {
      return (
        <div className="navigation">
          <button className="navigation--button" onClick={() => previousStep()}>
            <FaArrowLeft size="2em" color="#9B27AF" />
          </button>
          <ProgressBar
            bgColor="#9B27AF"
            className="progress"
            completed={progress}
          />
          <button
            className="navigation--button"
            onClick={() => nextStep()}
            style={state.unit === "" ? { pointerEvents: "none" } : {}}
          >
            {" "}
            <FaArrowRight
              size="2em"
              color={state.unit === "" ? "#bebebe" : "#9B27AF"}
            />
          </button>
        </div>
      );
    }

    if (type === "unit") {
      return (
        <div className="navigation">
          <button className="navigation--button" onClick={() => previousStep()}>
            <FaArrowLeft size="2em" color="#9B27AF" />
          </button>
          <ProgressBar
            bgColor="#9B27AF"
            className="progress"
            completed={progress}
          />
          <button className="navigation--button" onClick={() => nextStep()}>
            {" "}
            <FaArrowRight size="2em" color="#9B27AF" />
          </button>
        </div>
      );
    }
    if (type === "info") {
      return (
        <div className="navigation">
          <button className="navigation--button" onClick={() => previousStep()}>
            <FaArrowLeft size="2em" color="#9B27AF" />
          </button>
          <ProgressBar
            bgColor="#9B27AF"
            className="progress"
            completed={progress}
          />
          <button
            className="navigation--button"
            style={{ pointerEvents: "none" }}
          >
            {" "}
            <FaArrowRight size="2em" color="#bebebe" />
          </button>
        </div>
      );
    }
  };

  const AlertComponent = () => {
    return alert.state ? (
      <Alert className="alert alert__warning">
        <p>{alert.message}</p>
      </Alert>
    ) : null;
  };

  //submit function
  const submit = async () => {
    setLoadingModal(true)
    setModalContent(<div className="main-modal-content"> <h2>Loading</h2><ReactLoading type={"bubbles"} color={"#9B27AF"} height={"50%"} width={"50%"} /></div>)
    const conditions =
      state.locationNumber === "" ||
      state.street === "" ||
      state.house === "" ||
      state.unit === "" ||
      state.plate === "" ||
      state.plate.length > 14 ||
      state.email === "" ||
      state.email.indexOf("@") === -1;
    if (!conditions && policiesCheck) {
      const addTodb = await addParking(
        {
          locationNumber: state.locationNumber,
          plate: state.plate,
          email: state.email,
          phone: state.phone,
          house: state.house,
          street: state.street,
          unit: state.unit,
          vehicleColor: state.vehicleColor,
          vehicleMake: state.vehicleMake,
          vehicleYear: state.vehicleYear
      }
    );

      if (addTodb.status) {
        setAlert({ state: false, message: "" });
        setState({
          locationNumber: "",
          street: "",
          house: "",
          unit: "",
          plate: "",
          email: "",
        });
        setPoliciesCheck({ check: false });
        setStep(7);
      } else {
        setAlert({ state: true, message: addTodb.error });
      }
    } else {
      setAlert({ state: true, message: "error please check the fields" });
    }
  };

  switch (step) {
    case 1:
      return (
        <>
          <PublicNavBar />
          <AlertComponent />
          <div className="generalForm--container">
            <Navigation progress={20} type="locationNumber" />
            <LocationForm
              nextStep={nextStep}
              previousStep={previousStep}
              checkLocationNumber={checkLocationNumber}
              locationNumber={state.locationNumber}
              locationNumberHandleChange={locationNumberHandleChange}
              streetHandler={streetHandler}
              setAlert={setAlert}
            />
          </div>
        </>
      );

    case 2:
      return (
        <>
          <PublicNavBar />
          <AlertComponent />
          <div className="generalForm--container">
            <Navigation progress={40} type="street" />
            <StreetForm
              street={state.street}
              streets={streets}
              streetHandleChange={streetHandleChange}
              nextStep={nextStep}
              previousStep={previousStep}
              houseHandler={houseHandler}
              setAlert={setAlert}
            />
          </div>
        </>
      );
    case 3:
      return (
        <>
          <PublicNavBar />
          <AlertComponent />
          <div className="generalForm--container">
            <Navigation progress={60} type="house" />
            <HouseForm
              houses={houses}
              house={state.house}
              houseHandleChange={houseHandleChange}
              nextStep={nextStep}
              previousStep={previousStep}
              unitHandler={unitHandler}
              setAlert={setAlert}
            />
          </div>
        </>
      );
    case 4:
      return (
        <>
          <PublicNavBar />
          <AlertComponent />
          <div className="generalForm--container">
            <Navigation progress={80} type="unit" />
            <UnitForm
              units={units}
              unit={state.unit}
              unitHandleChange={unitHandleChange}
              nextStep={nextStep}
              previousStep={previousStep}
              setAlert={setAlert}
            />
          </div>
        </>
      );
    case 5:
      return (
        <>
          <PublicNavBar />
          <AlertComponent />
          <div className="generalForm--container">
            <Navigation progress={100} type="info" />
            <PersonalInfo
              plate={state.plate}
              vehicleColor={state.vehicleColor}
              personalInfoHandleChange={personalInfoHandleChange}
              policiesCheckHandleChange={policiesCheckHandleChange}
              policiesCheck={policiesCheck}
              submitButton={submitButton}
              nextStep={nextStep}
              previousStep={previousStep}
              submit={submit}
            />
          </div>
          <GenericModal state={loadingModal} setState={""}>
        {modalContent}
      </GenericModal>
        </>
      );
    case 6:
      return (
        <>
          <PublicNavBar />
          <div className="generalForm--container">
              <DataForm
              email={state.email}
              plate={state.plate}
              phone={state.phone}
              vehicleMake= {state.vehicleMake}
              vehicleColor={state.vehicleColor}
              vehicleYear = {state.vehicleYear}
              personalInfoHandleChange={personalInfoHandleChange}
              policiesCheckHandleChange={policiesCheckHandleChange}
              policiesCheck={policiesCheck}
              submitButton={submitButton}
              nextStep={nextStep}
              previousStep={previousStep}
              submit={submit}
              />
          </div>
        </>
      );

      case 7:
      return (
        <>
          <PublicNavBar />
          <div className="generalForm--container">
            <div className="success--card">
              <AiFillCheckCircle size="5rem" color="#198754" />
              <h1>Success!</h1>
              <button style={{ width: '100%' }}>Extend my parking permit</button>
              <div className="spacer--m"></div>
              <a
                className="link"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Go back
              </a>
            </div>
          </div>
        </>
      );
      default: 
        return null
  }
};

export default GeneralForm;
